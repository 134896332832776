import { gql } from "@apollo/client"

export const GET_ORGANIZATIONS_BY_ENTERPRISE = gql`
  query organizations($staff_id: ID!, $enterprise_id: ID!) {
    organizationsByEnterpriseUid(
      staff_id: $staff_id
      enterprise_id: $enterprise_id
    ) {
      organization_id
      organization_name
      organization_status
    }
  }
`

export const GET_USERS_FROM_LIST = gql`
  query get_users_from_list($list: [ID]) {
    usersFromList(list: $list) {
      staff_id
      staff_full_name
      organization_id
      organization_name
    }
  }
`
export const GET_STAFF = gql`
  query get_user($staff_id: ID!, $organization_id: ID!) {
    user(staff_id: $staff_id, organization_id: $organization_id) {
      staff_name_first
      staff_name_last
      staff_full_name
    }
  }
`

export const GET_PARTICIPANT = gql`
  query get_participant($participant_id: ID!, $organization_id: ID!) {
    participant(
      participant_id: $participant_id
      organization_id: $organization_id
    ) {
      participant_name_first
      participant_name_last
      participant_create_date
    }
  }
`

export const GET_LOCATIONS = gql`
  query get_locations($staff_id: ID!, $organization_id: ID!) {
    locations: locationsByOrganization(
      staff_id: $staff_id
      organization_id: $organization_id
    ) {
      location_id
      location_name
      location_status
    }
  }
`
