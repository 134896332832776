import { useContext, useState, useEffect } from "react"
import { BrowserRouter as Router } from "react-router-dom"

import axios from "axios"

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
} from "@apollo/client"

import { gql, useQuery, useLazyQuery } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"

// Context
import { UserContext } from "./context/userContext"
import { FirebaseContext } from "./firebase"

// Comps
import RoutesBuilder from "./components/Routes"
import LoaderFullPage from "./components/Loaders"

// Pages
import Login from "./pages/Login/"

import endpoint from "./config/endpoint"

const GET_PARTICIPANT = gql`
  query AuthenticatedUser($participant_id: ID!) {
    participant: authenticatedUser(participant_id: $participant_id) {
      participant_id
      participant_membership_id
      participant_membership_status
      participant_name_first
      participant_name_last
      participant_email_account
      participant_create_date
      participant_alias
      staff_role
      staff_role_name
      enterprise_id
      enterprise_alias
      organization_id
      organization_alias
      organization_name
      participant_profile_image
      staff_assigment_list {
        staff_id
        staff_name_first
        staff_name_last
        staff_email_address
        staff_alias
      }
      __typename
    }
  }
`

const GET_MICROSERVICE_TOKENS = gql`
  {
    telerecovery: token(microservice_id: 1) {
      jwt
    }
    appointments: token(microservice_id: 2) {
      jwt
    }
    notifications: token(microservice_id: 4) {
      jwt
    }
    tasks: token(microservice_id: 5) {
      jwt
    }
    assessments: token(microservice_id: 6) {
      jwt
    }
    documentSign: token(microservice_id: 8) {
      jwt
    }
  }
`

function HandleIsAuth({ participantId, token }) {
  // const { loading, data } = useQuery(GET_PARTICIPANT, {
  //   variables: { participant_id: participantId },
  // })
  const [payload, setPayload] = useState(null)
  const [getParticipant, { loading, data }] = useLazyQuery(GET_PARTICIPANT)
  const [participantData, setParticipantData] = useState(null)

  const [tokenNotification, setTokenNotification] = useState(null)
  const [tokenTask, setTokenTask] = useState(null)
  const [tokenDocuments, setTokenDocuments] = useState(null)
  const [tokenAppointments, setTokenAppointments] = useState(null)
  const [tokenTelerecovery, setTokenTelerecovery] = useState(null)
  const [tokenAssessments, setTokenAssessments] = useState(null)

  const { loading: fetchingServiceTokens } = useQuery(GET_MICROSERVICE_TOKENS, {
    onCompleted: (tokens) => {
      setTokenNotification(tokens?.notifications?.jwt)
      setTokenTask(tokens?.tasks?.jwt)
      setTokenDocuments(tokens?.documentSign?.jwt)
      setTokenAppointments(tokens?.appointments?.jwt)
      setTokenTelerecovery(tokens?.telerecovery?.jwt)
      setTokenAssessments(tokens?.assessments?.jwt)
    },
    onError: (error) => {
      console.log("useQuery: Error when fetching microservice tokens", error)
    },
  })

  useEffect(() => {
    if (!loading && data) {
      setParticipantData(data.participant)
    }
  }, [loading, data])

  useEffect(() => {
    getParticipant({
      variables: { participant_id: participantId },
      fetchPolicy: "no-cache",
    })
  }, [payload])

  //System notifications
  const [notification_error, setNotificationError] = useState(undefined)
  const [notification_action, setNotificationAction] = useState(undefined)
  const [notification_Information, setNotificationInformation] =
    useState(undefined)

  const [toggleAppointment, setToggleAppointment] = useState({
    toggle: false,
    page: "default",
  })

  async function setToggleAppointments(val) {
    setToggleAppointment(val)
  }

  if (loading || !participantData || fetchingServiceTokens)
    return <LoaderFullPage />

  const ctxValue = {
    getParticipant,
    setPayload,
    ...participantData,
    token,
    tokenNotification,
    tokenTask,
    tokenDocuments,
    tokenAppointments,
    notification_error,
    setNotificationError,
    notification_Information,
    setNotificationInformation,
    notification_action,
    setNotificationAction,
    toggleAppointment,
    setToggleAppointments,
    tokenTelerecovery,
    tokenAssessments,
  }
  return (
    <UserContext.Provider value={ctxValue}>
      <RoutesBuilder />
    </UserContext.Provider>
  )
}

function App() {
  const [user, setUser] = useState(null)
  const [token, setToken] = useState(null)

  const firebase = useContext(FirebaseContext)

  useState(() => {
    firebase.auth.onAuthStateChanged(async (user) => {
      const getToken = async () => {
        try {
          const res = await axios.post(`${endpoint}/participant-token`, {
            staff_id: user.uid,
          })
          setToken(res.data.token)
        } catch (err) {
          setToken(null)
        }
      }

      user && getToken()

      return user ? setUser(user) : setUser(null)
    })
  }, [])

  if (!user || !token) return <Login />

  const setAuthorizationLink = setContext(() => ({
    headers: { Authorization: token },
  }))

  const httpLink = new HttpLink({ uri: `${endpoint}/graphql` })

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: setAuthorizationLink.concat(httpLink),
  })

  return (
    <ApolloProvider client={client}>
      <Router>
        <HandleIsAuth participantId={user.uid} token={token} />
      </Router>
    </ApolloProvider>
  )
}

export default App
