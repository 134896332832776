import React from "react"
import ReactDOM from "react-dom"
import Firebase, { FirebaseContext } from "./firebase"

import "./styles/global.css"

// PrimeReact
import "primereact/resources/themes/bootstrap4-light-blue/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"

import "./styles/rewrites.css"

import App from "./App"

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>,

  document.getElementById("root")
)
