import axios from "axios";
import { appointmentsApiUrl } from "../../config/microservicesUrls";

var api_url = appointmentsApiUrl();
//var api_url = "http://localhost:5000";


// $.notify("Warning: Self-destruct in 3.. 2..", "warn");

async function AxiosPost(jwt, endpoint, data) {
  const axiosObj = axios.create();

  var requestConfig = {
    headers: {
      Authorization: "Bearer " + jwt,
      "Content-Type": "application/json",
    },
  };

  var x = await axiosObj
    .post(`${api_url}${endpoint}`, data, requestConfig)
    .then((responseBody) => {
      return responseBody.data;
    });

  return x;
}

async function AxiosDelete(jwt, endpoint, data) {
  const axiosObj = axios.create();

  var requestConfig = {
    headers: {
      Authorization: "Bearer " + jwt,
      "Content-Type": "application/json",
    },
  };

  var x = await axiosObj
    .delete(`${api_url}${endpoint}`, requestConfig)
    .then((responseBody) => {
      return responseBody.data;
    });

  return x;
}

async function AxiosGet(jwt, endpoint, params) {
  const axiosObj = axios.create();

  let requestConfig = {
    headers: {
      Authorization: "Bearer " + jwt
    },
  };

  if (!!params) {
    requestConfig = { ...requestConfig, params: params };
  }

  let x = await axiosObj
    .get(`${api_url}${endpoint}`, requestConfig)
    .then((responseBody) => {
      return responseBody.data;
    });

  return x;
}

export async function createNewAppointment(jwt, payload) {
  return await AxiosPost(jwt, "/Api/OnSched/Create", payload);
}

export async function createNewOrgAppointment(jwt, payload) {
  return await AxiosPost(jwt, "/Api/OrgScheduling/Create", payload);
}

export async function editOrgAppointment(jwt, payload, oldDate) {
  return await AxiosPost(
    jwt,
    "/Api/OrgScheduling/Edit?oldDate=" + oldDate,
    payload
  );
}

export async function claimOrgAppointment(
  jwt,
  id,
  peerInfo,
  fromRecurring,
  startDate = null,
  endDate = null
) {
  return await AxiosPost(
    jwt,
    "/Api/OrgScheduling/Claim?id=" +
    id +
    "&fromRecurring=" +
    fromRecurring +
    "&startDateTime=" +
    startDate +
    "&endDateTime=" +
    endDate,
    peerInfo
  );
}

export async function updateAppointment(jwt, id, payload, date) {
  return await AxiosPost(
    jwt,
    "/Api/OnSched/Reschedule/" + id + "?date=" + date,
    payload
  );
}

export async function cancelAppointment(jwt, id, date, entire) {
  return await AxiosPost(
    jwt,
    "/Api/OnSched/Cancel/" + id + "?date=" + date + "&entire=" + entire
  );
}

export async function getAllAppointments(jwt, peerId, start, end, orgId) {
  return await AxiosGet(
    jwt,
    "/Api/OnSched/All?start=" +
      start +
      "&end=" +
      end +
      "&peerId=" +
      peerId +
      "&orgId=" +
      orgId
  );
}

export async function getAllMinifiedAppointments(
  jwt,
  email,
  start,
  end,
  caseId,
  recurring = false,
  pending = false,
  orgId = null
) {
  if (pending == null) pending = false;
  return await AxiosGet(jwt, "/Api/OnSched/Minified/All", {
    staffId: email,
    start: start,
    end: end,
    caseId: caseId,
    recurring: recurring,
    pending: pending,
    orgId: orgId,
  });
}

export async function getAvailabilities(jwt, email) {
  email = encodeURIComponent(email);
  return await AxiosGet(jwt, "/Api/Availability/Get?peerEmail=" + email);
}

export async function getMetadata(jwt, id) {
  return await AxiosGet(jwt, "/Api/OnSched/Get/Metadata?apptId=" + id);
}

export async function setAvailability(jwt, payload) {
  return await AxiosPost(jwt, "/Api/Availability/Create", payload);
}

export async function editAvailability(jwt, payload) {
  return await AxiosPost(jwt, "/Api/Availability/Edit", payload);
}

export async function sendParticipantAppointment(jwt, payload) {
  return await AxiosPost(jwt, "/Api/OnSched/Participant/Create", payload);
}

export async function deleteOrgAppointment(
  jwt,
  id,
  isFromRecurring,
  startDateTime
) {
  return await AxiosDelete(
    jwt,
    "/Api/OrgScheduling/Delete/" +
      id +
      "?isFromRecurring=" +
      isFromRecurring +
      "&startDateTime=" +
      startDateTime
  );
}

export async function deleteOrgController(jwt, id) {
  return await AxiosDelete(jwt, "/Api/OrgScheduling/Controller/Delete/" + id);
}

export async function deleteAvailability(jwt, availabilityId) {
  return await AxiosDelete(jwt, "/Api/Availability/Delete/" + availabilityId);
}

export async function editParticipantAppointment(jwt, payload) {
  return await AxiosPost(jwt, "/Api/OnSched/Participant/Edit", payload);
}

export async function declineParticipantAppointment(jwt, id) {
  return await AxiosDelete(jwt, "/Api/OnSched/Participant/Decline/" + id);
}

/*export default function getEngagementDates(id) {
    return new Promise((resolve, reject) => {
        $.ajax({
            type: "GET",
            url: "/Api/OnSched/Get/NextLastEngagement?caseId=" + id,
            contentType: "application/json",
            success: function (response) {
                resolve(response);
            },
            error: function (err) {
                console.error(err);
            }
        });
    })
}
export default async function updateNextEngagement(caseId, date) {
    let token = await getCsrfToken();
    return new Promise((resolve, reject) => {
        $.ajax({
            type: "POST",
            url: drupalSettings.path.baseUrl + "api/update-next-engagement",
            contentType: "application/json",
            data: JSON.stringify({
                nextEngagementDate: date,
                caseId: caseId
            }),
            dataType: "json",
            headers: {
                "X-CSRF-Token": token,
            },
            success: function (response) {
                resolve(response);
            },
            error: function (err) {
                console.error(err);
            }
        });
    })
}
*/
