import { useContext, useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"

//PrimeReact
import { Button } from "primereact/button"
import { TabPanel, TabView } from "primereact/tabview"

//styling
import assessStyles from "../../../styles/assessments/Assessment.module.css"
import myStyles from "../../../styles/assessments/ParticipantAssessments.module.css"

// Context
import { UserContext } from "../../../context/userContext"

//SOAR Components
import ParticipantSummaryInfo from "../../../components/sidebars/ParticipantSummaryInfo"

//Assessments components
import ParticipantAssessmentsOverview from "./ParticipantAssessmentsOverview"
import ParticipantAssessmentsCompleted from "./ParticipantAssessmentsCompleted"
import AssessmentForm from "../AssessmentForm/AssessmentForm"
import ParticipantAssessmentsSchedules from "./ParticipantAssessmentsSchedules"
import { assessmentsApiOrganizationGet } from "../assessmentsApi"
import { LogError } from "../../../helpers/logger"
import ParticipantAssessmentBlockDialog from "./ParticipantAssessmentBlockDialog"

export default function ParticipantAssessments() {
  const userCtx = useContext(UserContext)
  const location = useLocation()

  const [isLoading, setIsLoading] = useState(true)
  const [isActive, setIsActive] = useState(false) //Indicates if the assessments module is active.

  const [tabViewIndex, setTabViewIndex] = useState(0)

  //Assessment block values:
  const [showAssessmentBlock, setShowAssessmentBlock] = useState(false)
  const [scheduledTaskId, setScheduledTaskId] = useState(undefined)

  //Assessment form values:
  const [newAssessmentId, setNewAssessmentId] = useState(undefined)
  const [completedId, setCompletedId] = useState(undefined)
  const [openReadOnly, setOpenReadOnly] = useState(true)
  const [showAssessmentForm, setShowAssessmentForm] = useState(false)

  const [triggerRefresh, setTriggerRefresh] = useState(false)

  const facilitatorProfilesRef = useRef([])

  useEffect(() => {
    if (location.state) {
      if (location.state.taskId) {
        //Open the Scheduled assessments tab view
        setTabViewIndex(2)
        //Show the task form to complete the task
        updateShowAssessmentBlock(true, location.state.taskId)
      } else if (location.state.page) {
        switch (location.state.page) {
          case "schedule":
            setTabViewIndex(2)
            break
          default:
            break
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  useEffect(() => {
    setIsLoading(true)
    assessmentsApiOrganizationGet(
      userCtx.tokenAssessments,
      userCtx.organization_id
    )
      .then((result) => {
        setIsActive(result && result.isActive)
      })
      .catch((error) => {
        if (error?.response?.data === "Organization not found") {
          setIsActive(false)
        } else {
          LogError("Unable to retrieve Assessments organization data", error)
          userCtx.setNotificationError(
            "Unable to confirm that the Assessment module is active for this organization"
          )
          setIsActive(false)
        }
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCtx.participant_id, userCtx.organization_id])

  const updateShowAssessmentBlock = (showBlock, showScheduledTaskId) => {
    if (showBlock) {
      setScheduledTaskId(showScheduledTaskId)
      setShowAssessmentBlock(true)
    } else {
      setShowAssessmentBlock(false)
      setScheduledTaskId(undefined)
    }
  }

  const setAssessmentId = (id, isCompletedId, readOnly) => {
    if (!isCompletedId) {
      setNewAssessmentId(id)
      setCompletedId(undefined)
      setOpenReadOnly(false)
      setShowAssessmentForm(true)
    } else {
      setNewAssessmentId(undefined)
      setCompletedId(id)
      setOpenReadOnly(readOnly || true)
      setShowAssessmentForm(true)
    }
  }

  return (
    <div className="layout-with-sidebar">
      <ParticipantSummaryInfo />
      {showAssessmentBlock ? (
        <ParticipantAssessmentBlockDialog
          showForm={true}
          scheduledTaskId={scheduledTaskId}
          onCancel={() => {
            updateShowAssessmentBlock(false)
            setTriggerRefresh(!triggerRefresh)
          }}
        />
      ) : null}
      {showAssessmentForm ? (
        <AssessmentForm
          showForm={showAssessmentForm}
          onCancel={() => {
            setShowAssessmentForm(false)
            setCompletedId(undefined)
          }}
          onSubmit={() => {
            setShowAssessmentForm(false)
            setCompletedId(undefined)
            setTriggerRefresh(!triggerRefresh)
          }}
          assessmentId={newAssessmentId}
          completedAssessmentId={completedId}
          readOnly={openReadOnly}
        />
      ) : null}

      <div className="main-content">
        <div className={myStyles.pageHeader}>
          <div className={myStyles.pageHeader}>
            <h1>Assessments</h1>
          </div>
          <div className={myStyles.pageHeaderActions}>
            <Button
              label={"Start new assessment"}
              type="button"
              className={`${assessStyles.button} ${myStyles.pageHeaderActionButton}`}
              onClick={() => {
                updateShowAssessmentBlock(true)
              }}
            />
          </div>
        </div>

        <div className={assessStyles.tabView}>
          <TabView
            activeIndex={tabViewIndex}
            onTabChange={(e) => {
              setTabViewIndex(e.index)
            }}
          >
            <TabPanel header={"Overview"}>
              <ParticipantAssessmentsOverview
                onViewAssessment={(completedAssessmentId) => {
                  setAssessmentId(completedAssessmentId, true)
                }}
                facilitatorProfiles={facilitatorProfilesRef.current}
                onSaveFacilitatorProfile={(
                  profile_id,
                  organization_id,
                  name
                ) => {
                  let currentFacilitators = facilitatorProfilesRef.current

                  if (
                    !currentFacilitators.some(
                      (profile) =>
                        profile.profile_id === profile_id &&
                        profile.organization_id === organization_id
                    )
                  ) {
                    currentFacilitators = [
                      ...currentFacilitators,
                      { profile_id, organization_id, name },
                    ]

                    facilitatorProfilesRef.current = currentFacilitators
                  }

                  return facilitatorProfilesRef.current
                }}
              />
            </TabPanel>
            <TabPanel header={"Completed"}>
              <ParticipantAssessmentsCompleted
                triggerRefresh={triggerRefresh}
                onEdit={(completedAssessmentId) => {
                  setAssessmentId(completedAssessmentId, true, false)
                }}
                onView={(completedAssessmentId) => {
                  setAssessmentId(completedAssessmentId, true, true)
                }}
              />
            </TabPanel>
            {isActive ? (
              <TabPanel header={"Scheduled"}>
                <ParticipantAssessmentsSchedules
                  triggerRefresh={triggerRefresh}
                  onProcessScheduledTask={(scheduledTaskId) => {
                    updateShowAssessmentBlock(true, scheduledTaskId)
                  }}
                />
              </TabPanel>
            ) : null}
          </TabView>
        </div>
      </div>
    </div>
  )
}
