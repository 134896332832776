import React, { useState, useEffect, useRef, useContext, useCallback } from "react"
import { LoaderMedium } from "../../../components/Loaders"
import moment from "moment"
import ChatMessage from "./ChatMessage"
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button'
import trcss from "../../../styles/Telerecovery.module.css"
import { HubConnectionBuilder } from "@microsoft/signalr"
import { UserContext } from "../../../context/userContext"
import { telerecoveryApiUrl } from "../../../config/microservicesUrls"
import { trApiGetChatHistory, trApiSendChatMessageToStaff } from "../telerecoveryApi"
import { staffNameInitials, groupMessagesByDate, getActualUTCTime } from "../Utils"
import ExternalLinkPopup from "../ExternalLinkPopup"

export default function ChatComponent({ chatConvSid, staffId, staffName, onNewMessage, participantIsInactive }) {
    const [messages, setMessages] = useState([])
    const [newSMSText, setNewSMSText] = useState("")
    const [loadingMessages, setLoadingMessages] = useState(true)
    const [sendingMessage, setSendingMessage] = useState(false)
    const [connection, setConnection] = useState(null)
    const [joinLink, setJoinLink] = useState(null)
    const [showExternalLinkPopup, setShowExternalLinkPopup] = useState(false)
    const messagesEndRef = useRef(null)
    const user = useContext(UserContext)

    useEffect(() => {
        const _connection = new HubConnectionBuilder()
            .withUrl(telerecoveryApiUrl() + "/notificationHub")
            .withAutomaticReconnect()
            .build()
        setConnection(_connection)
    }, [])

    const checkIncomingMessage = useCallback((fromId, convSid, body) => {
        let newMsg = {
            dateUpdated: getActualUTCTime(),
            body: body,
            fromId: fromId
        }
        if (onNewMessage) {
            onNewMessage(newMsg)
        }
        if (chatConvSid === convSid && fromId !== user.participant_id) {
            setMessages((oldArray) => [...oldArray, newMsg])
            scrollToBottom()
        }
    }, [chatConvSid])

    useEffect(() => {
        setLoadingMessages(true)
        trApiGetChatHistory(user.tokenTelerecovery, chatConvSid)
            .then(
                (response) => {
                    const _messages = Array.from(response)
                    setMessages(_messages)
                    setLoadingMessages(false)
                    scrollToBottom()
                },
                (err) => console.log(err)
            )
            .catch(() => {
                setLoadingMessages(false)
            })

        if (connection) {
            // Clean up previous listeners to avoid multiple subscriptions
            connection.off("IncomingChatMessage");

            // Only attempt to start the connection if it's disconnected
            if (connection.state === "Disconnected") {
                connection.start()
                    .then(() => {
                        connection.on("IncomingChatMessage", (fromId, convSid, body) => {
                            checkIncomingMessage(fromId, convSid, body)
                        });
                    })
                    .catch((e) => console.log("Connection failed: ", e));
            } else {
                // Re-subscribe to the event
                connection.on("IncomingChatMessage", (fromId, convSid, body) => {
                    checkIncomingMessage(fromId, convSid, body)
                });
            }
            // Cleanup function to unregister event handlers
            return () => {
                if (connection) {
                    connection.off("IncomingChatMessage");
                }
            };
        }
    }, [chatConvSid, staffId, connection])

    const scrollToBottom = () => {
        messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" })
    }

    const sendMessage = () => {
        if (newSMSText === "" || sendingMessage) {
            return
        }

        setSendingMessage(true)

        let smsData = {
            body: newSMSText,
            fromId: user.participant_id,
            convSid: chatConvSid,
        }

        trApiSendChatMessageToStaff(user.tokenTelerecovery, smsData)
            .then(
                (response) => {
                    let newMsg = {
                        dateUpdated: getActualUTCTime(),
                        body: smsData.body,
                        fromId: user.participant_id,
                    }
                    setMessages((oldArray) => [...oldArray, newMsg])
                    setSendingMessage(false)
                    setNewSMSText("")
                    scrollToBottom()
                },
                (err) => console.log(err)
            )
    }

    return (
        <div
            className={trcss.chatWindow}
        >
            <ExternalLinkPopup
                link={joinLink}
                showPopup={showExternalLinkPopup}
                setShowPopup={setShowExternalLinkPopup}
            />
            <div className={trcss.chatWindowHeader}>
                {staffName &&
                    <div>
                        <span className={trcss.userInitials}>{staffNameInitials(staffName)}</span>
                        <span style={{ marginLeft: "6px", fontWeight: "600", fontSize: "12px" }}>{staffName.full_name}</span>
                    </div>
                }
                {messages && messages.length > 0 && (
                    <p style={{ fontSize: "11px", alignSelf: "center" }}>
                        {`Last message: ${moment(messages[messages.length - 1].dateUpdated).fromNow()}`}
                    </p>
                )}
            </div>
            <div className={trcss.chatHistoryContainer}>
                {loadingMessages === true && <LoaderMedium />}
                {(!messages || messages.length === 0) && !loadingMessages && (
                    <span className={trcss.convEmptyMsg}>
                        This conversation is empty.
                    </span>
                )}
                <ul className={trcss.chatWindowList} style={{ paddingTop: "10px" }}>
                    {!loadingMessages &&
                        messages &&
                        groupMessagesByDate(messages).map((grupedMessage, i) => (
                            <li key={"msg-" + i}>
                                <ChatMessage
                                    messages={grupedMessage.messages}
                                    fromName={staffName}
                                    setJoinLink={setJoinLink}
                                    setShowExternalLinkPopup={setShowExternalLinkPopup}
                                ></ChatMessage>
                            </li>
                        ))}
                </ul>
                <div ref={messagesEndRef}></div>
            </div>
            <div className={trcss.chatWindowFooter}>
                <InputTextarea
                    placeholder="Type your message"
                    autoResize
                    className={trcss.newMessageBox}
                    value={newSMSText}
                    onChange={(e) => setNewSMSText(e.target.value)}
                    rows={1}
                    disabled={sendingMessage || participantIsInactive()}
                />
                <Button
                    className={trcss.sendBtn}
                    onClick={() => sendMessage()}
                    loading={sendingMessage}
                    disabled={sendingMessage || participantIsInactive()}
                />
            </div>
        </div>
    )
}