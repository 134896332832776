import { DateTime } from "luxon"

//Assessment components
import { useCallback, useContext, useEffect, useState } from "react"
import AssessmentBlock from "../AssessmentBlock/AssessmentBlock"

//PrimeReact
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"
import { ConfirmDialog } from "primereact/confirmdialog" // For <ConfirmDialog /> component
import { confirmDialog } from "primereact/confirmdialog" // For confirmDialog method

//styling
import myStyles from "../../../styles/assessments/ParticipantAssessments.module.css"
import assessStyles from "../../../styles/assessments/Assessment.module.css"
import {
  assessmentsApiScheduleTaskCompletePut,
  assessmentsApiScheduleTaskGet,
  assessmentsApiSubmitSoarSessionPost,
} from "../assessmentsApi"

// Context
import { UserContext } from "../../../context/userContext"
import { LogError } from "../../../helpers/logger"

export default function ParticipantAssessmentBlockDialog({
  showForm,
  scheduledTaskId,
  onCancel,
}) {
  const userCtx = useContext(UserContext)

  const [isLoading, setIsLoading] = useState(true)
  const [isCompletingTask, setIsCompletingTask] = useState(false)
  const [task, setTask] = useState(undefined)
  const [assessments, setAssessments] = useState([])
  const [sessionValues, setSessionValues] = useState({
    locationId: "",
    sessionDate: new Date(),
  })
  const [startUtcTime] = useState(DateTime.utc())

  const loadTaskAssessments = useCallback(async () => {
    try {
      if (scheduledTaskId) {
        setIsLoading(true)

        const result = await assessmentsApiScheduleTaskGet(
          userCtx.tokenAssessments,
          scheduledTaskId
        )

        if (result) {
          const dueDate = DateTime.fromISO(result?.dateDue)

          setTask({
            id: result.taskId,
            staff_id: result.staffId,
            dateDue: result.dateDue,
            dateCompleted: result.dateCompleted,
            name: result.name,
            organization_id: result.organizationId,
            taskIsDue:
              dueDate.startOf("day").ts <= DateTime.now().startOf("day").ts,
          })

          setAssessments(
            result?.assessments?.map((assessment) => {
              return {
                assessment_id: assessment.id,
                completed_assessment_id: assessment.completedAssessmentId,
                session_assessment: false,
                required: assessment.isRequired,
                assessment_name: assessment.name,
              }
            }) || []
          )

          setIsLoading(false)
        } else {
          userCtx.setNotificationError("Assigned assessment task not found")
          onCancel()
        }
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      LogError("Unable to load Assessment block interface", error)
      userCtx.setNotificationError("Unable to load assessment interface")
      onCancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduledTaskId])

  useEffect(() => {
    loadTaskAssessments()
  }, [loadTaskAssessments])

  const getSessionPayload = () => {
    let startDateTime = startUtcTime
    let endDateTime = DateTime.utc()

    if (sessionValues.sessionDate) {
      const sessionDate = DateTime.fromJSDate(sessionValues.sessionDate)
      startDateTime = startDateTime.set({
        day: sessionDate.day,
        month: sessionDate.month,
        year: sessionDate.year,
      })
      endDateTime = endDateTime.set({
        day: sessionDate.day,
        month: sessionDate.month,
        year: sessionDate.year,
      })
    }

    const payload = {
      startDateTime: startDateTime.toISO(),
      endDateTime: endDateTime.toISO(),
      locationId: sessionValues?.location?.location_id,
      locationName: sessionValues?.location?.location_name,
      enterpriseId: userCtx.enterprise_id,
      organizationId: userCtx.organization_id,
      participantId: userCtx.participant_id,
      staffId: "0", //Staff used for self assessment
      staffAlias: "1", //Staff used for self assessment
    }

    return payload
  }

  const markTaskAsComplete = async () => {
    try {
      setIsLoading(true)
      setIsCompletingTask(true)

      const payload = getSessionPayload()

      await assessmentsApiScheduleTaskCompletePut(
        userCtx.tokenAssessments,
        task?.id,
        payload
      )
      onCancel()
      setIsLoading(false)
      setIsCompletingTask(false)
    } catch (error) {
      LogError("Unable to complete task", error)
      userCtx.setNotificationError(
        "Unable to mark assessment task as completed"
      )
      setIsLoading(false)
      setIsCompletingTask(false)
    }
  }

  const completeTask = () => {
    if (
      assessments.some(
        (assessment) =>
          !assessment.completed_assessment_id && assessment.required
      )
    ) {
      userCtx.setNotificationError(
        "Not all required assessments have been completed yet"
      )
    } else if (
      assessments.some((assessment) => !assessment.completed_assessment_id)
    ) {
      confirmDialog({
        message: (
          <>
            <p>
              There are incomplete assessments that will be marked as declined
            </p>
            <p>Do you want to complete this task?</p>
          </>
        ),
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        defaultFocus: "accept",
        accept: async () => {
          markTaskAsComplete()
        },
      })
    } else {
      markTaskAsComplete()
    }
  }

  const submitSession = async () => {
    try {
      setIsLoading(true)
      setIsCompletingTask(true)

      let payload = getSessionPayload()
      const completedAssessments = assessments
        .filter((assessment) => assessment.completed_assessment_id !== null)
        .map((assessment) => assessment.completed_assessment_id)

      payload.completedAssessmentIds = completedAssessments

      await assessmentsApiSubmitSoarSessionPost(
        userCtx.tokenAssessments,
        payload
      )

      onCancel()

      setIsCompletingTask(false)
      setIsLoading(false)
    } catch (error) {
      LogError("Unable to complete the session", error)
      userCtx.setNotificationError("Unable to submit session data")
      setIsLoading(false)
      setIsCompletingTask(false)
    }
  }

  const completeSession = async () => {
    try {
      if (assessments.length > 0) {
        if (
          !assessments.some((assessment) => assessment.completed_assessment_id)
        ) {
          userCtx.setNotificationInformation(
            "Please complete an assessment to submit"
          )
        } else if (
          assessments.some((assessment) => !assessment.completed_assessment_id)
        ) {
          confirmDialog({
            message: (
              <>
                <p>
                  There are incomplete assessments that will be ignored for this
                  session
                </p>
                <p>Do you want to continue?</p>
              </>
            ),
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            defaultFocus: "accept",
            accept: async () => {
              submitSession()
            },
          })
        } else {
          submitSession()
        }
      } else {
        userCtx.setNotificationError(
          "There are no assessments linked to this session"
        )
      }
    } catch (error) {
      LogError("Unable to complete session", error)
      userCtx.setNotificationError("Unable to submit session data")
      setIsLoading(false)
      setIsCompletingTask(false)
    }
  }

  const closeDialog = () => {
    if (task && !task?.dateCompleted) {
      confirmDialog({
        message: (
          <>
            <p>
              This task will remain open until the "Complete scheduled task"
              button is clicked
            </p>
            <p>Do you want to continue?</p>
          </>
        ),
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        defaultFocus: "accept",
        accept: async () => {
          onCancel()
        },
      })
    } else if (
      !task &&
      assessments.some((assessment) => assessment.completed_assessment_id)
    ) {
      confirmDialog({
        message: (
          <>
            <p>There are pending assessments that will be discarded.</p>
            <br />
            <p>Do you want to continue?</p>
          </>
        ),
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        defaultFocus: "accept",
        accept: async () => {
          onCancel()
        },
      })
    } else {
      onCancel()
    }
  }

  return (
    <Dialog
      header={scheduledTaskId ? "Scheduled assessments" : `Ad hoc Assessments`}
      visible={showForm}
      onHide={closeDialog}
      closable={!isCompletingTask}
      className={myStyles.blockDialog}
    >
      <ConfirmDialog />
      <AssessmentBlock
        assessmentConfig={assessments}
        assessmentTaskProfile={task}
        onSubmitAssessments={(assessments) => {
          setAssessments(assessments)
        }}
        onCompleteTask={() => {
          markTaskAsComplete()
        }}
        onSetParentLoading={(isChildLoading) => {
          setIsLoading(isChildLoading)
        }}
        parentIsLoading={isLoading}
        sessionMode={true}
        onUpdateSessionValues={setSessionValues}
        hideSessionBlock={true}
        readOnly={isLoading}
      />
      <div className={myStyles.blockFooter}>
        <div className={myStyles.blockLayoutButtons}>
          {task ? (
            <Button
              label={
                task?.taskIsDue ? "Complete scheduled task" : "Task is not due"
              }
              type="button"
              onClick={completeTask}
              className={assessStyles.button}
              disabled={
                isLoading ||
                !task?.taskIsDue ||
                task?.dateCompleted ||
                assessments.some(
                  (assessment) =>
                    !assessment.completed_assessment_id && assessment.required
                )
              }
              loading={isCompletingTask}
            />
          ) : (
            <Button
              label={"Submit session"}
              type="button"
              onClick={completeSession}
              className={assessStyles.button}
              disabled={
                isLoading ||
                !assessments.some(
                  (assessment) => assessment.completed_assessment_id
                )
              }
              loading={isCompletingTask}
            />
          )}
          <Button
            label="Close"
            type="button"
            onClick={closeDialog}
            className={assessStyles.button}
            disabled={isCompletingTask}
          />
        </div>
      </div>
    </Dialog>
  )
}
