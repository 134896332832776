import { useState } from "react"
import { DateTime } from "luxon"

//Styles
import myStyles from "../../../styles/assessments/ParticipantAssessments.module.css"
import assessStyles from "../../../styles/assessments/Assessment.module.css"

//PrimeReact
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { Calendar } from "primereact/calendar"
import { InputSwitch } from "primereact/inputswitch"

export default function ParticipantAssessmentFilters({
  filters,
  isScheduled,
  onSetFilters,
  onClose,
}) {
  const [tempFilters, setTempFilters] = useState(filters)

  return (
    <Dialog
      header={"Filter"}
      visible={true}
      onHide={onClose}
      className={myStyles.filterDialog}
      closable={false}
    >
      <div
        className={
          isScheduled ? myStyles.filterLayoutSchedule : myStyles.filterLayout
        }
      >
        <div className={myStyles.filterLayoutFrom}>
          <label>From date</label>
          <Calendar
            className={myStyles.filterControl}
            placeholder="Select a start date filter"
            value={tempFilters.completedFrom}
            onChange={(e) => {
              setTempFilters({ ...tempFilters, completedFrom: e.value })
            }}
          />
        </div>
        <div className={myStyles.filterLayoutTo}>
          <label>To date</label>
          <Calendar
            className={myStyles.filterControl}
            placeholder="Select an end date filter"
            value={tempFilters.completedTo}
            onChange={(e) => {
              let date = DateTime.fromJSDate(e.value)
              date = date.set({ hour: 23, minute: 59, second: 59 })

              setTempFilters({ ...tempFilters, completedTo: date.toJSDate() })
            }}
          />
        </div>
        {isScheduled ? (
          <>
            <div className={`${myStyles.filterLayoutFromScheduleCompleted} `}>
              <label>Include completed</label>
            </div>
            <div
              className={`${myStyles.filterLayoutFromScheduleCompletedInput} ${assessStyles.switchControl}`}
            >
              <InputSwitch
                checked={tempFilters.includeCompleted}
                onChange={(e) => {
                  setTempFilters({
                    ...tempFilters,
                    includeCompleted: e.value,
                  })
                }}
              />
            </div>

            <div className={`${myStyles.filterLayoutFromScheduleUpcoming}`}>
              <label>Include upcoming</label>
            </div>
            <div
              className={`${myStyles.filterLayoutFromScheduleUpcomingInput} ${assessStyles.switchControl}`}
            >
              <InputSwitch
                checked={tempFilters.includeUpcoming}
                onChange={(e) => {
                  setTempFilters({
                    ...tempFilters,
                    includeUpcoming: e.value,
                  })
                }}
              />
            </div>
          </>
        ) : null}
        <div className={myStyles.filterLayoutSearch}>
          <label>Search phrase</label>
          <InputText
            className={myStyles.filterControl}
            placeholder="Enter a search phrase"
            value={tempFilters.searchPhrase}
            onChange={(e) => {
              setTempFilters({ ...tempFilters, searchPhrase: e.target.value })
            }}
          />
        </div>
        <div className={myStyles.filterLayoutReset}>
          <Button
            label="X  Reset all filters"
            className={assessStyles.actionButton}
            type="button"
            onClick={() => {
              onSetFilters({})
            }}
          />
        </div>
        <div className={myStyles.filterLayoutButtons}>
          <Button
            label="Cancel"
            className={assessStyles.button}
            type="button"
            onClick={() => {
              onClose()
            }}
          />
          <Button
            label="Apply"
            className={assessStyles.button}
            type="button"
            onClick={() => {
              onSetFilters(tempFilters)
            }}
          />
        </div>
      </div>
    </Dialog>
  )
}
