function testValue(condition, conditionalValue, value) {
  let conditionIsTrue = false
  let checkMultiValues = []

  const stringValue = `${value}`

  switch (condition) {
    case "==":
      conditionIsTrue = stringValue === conditionalValue
      break
    case "<":
      conditionIsTrue = +stringValue < +conditionalValue
      break
    case "<=":
      conditionIsTrue = +stringValue <= +conditionalValue
      break
    case ">":
      conditionIsTrue = +stringValue > +conditionalValue
      break
    case ">=":
      conditionIsTrue = +stringValue >= +conditionalValue
      break
    case "!=":
      conditionIsTrue = stringValue !== conditionalValue
      break
    case "IN":
      checkMultiValues = conditionalValue.split(";")
      conditionIsTrue = checkMultiValues.includes(stringValue)
      break
    case "NOT IN":
      checkMultiValues = conditionalValue.split(";")
      conditionIsTrue = !checkMultiValues.includes(stringValue)
      break
    default:
      return undefined
  }

  return conditionIsTrue
}

export function validateSingeValueType(question, sourceAnswer) {
  let conditionIsTrue = testValue(
    question.conditionalType,
    question.conditionalValue,
    sourceAnswer
  )

  let conditionalDisplay = question.conditionalDisplay ? conditionIsTrue : true
  let conditionalRequired = question.conditionalRequired
    ? conditionIsTrue
    : true

  return { display: conditionalDisplay, required: conditionalRequired }
}

export function validateMultiValueType(question, sourceAnswers) {
  let conditionIsTrue = sourceAnswers.some((answer) => {
    return testValue(
      question.conditionalType,
      question.conditionalValue,
      answer
    )
  })

  let conditionalDisplay = question.conditionalDisplay ? conditionIsTrue : true
  let conditionalRequired = question.conditionalRequired
    ? conditionIsTrue
    : true

  return { display: conditionalDisplay, required: conditionalRequired }
}
