import { useCallback, useContext, useEffect, useState } from "react"
import { DateTime } from "luxon"

// Context
import { UserContext } from "../../../context/userContext"

//styling
import assessStyles from "../../../styles/assessments/Assessment.module.css"
import myStyles from "../../../styles/assessments/ParticipantAssessments.module.css"

//PrimeReact
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import { Paginator } from "primereact/paginator"

//Helpers
import { LoaderMedium } from "../../../components/Loaders"
import { LogError } from "../../../helpers/logger"

//Assessments components:
import { assessmentsApiCompleteListPost } from "../assessmentsApi"
import { Dropdown } from "primereact/dropdown"
import ParticipantAssessmentFilters from "./ParticipantAssessmentsFilters"

export default function ParticipantAssessmentsCompleted({
  triggerRefresh,
  onView,
  onEdit,
}) {
  const userCtx = useContext(UserContext)

  const [isLoading, setIsLoading] = useState(true)
  const [dataset, setDataset] = useState(undefined)
  const [filters, setFilters] = useState({
    sortField: "assessmentName",
    sortOrder: "a-z",
    rows: 5,
    first: 0,
    page: 0,
  })
  const [additionalFilters, setAdditionalFilters] = useState({})
  const [showFilterDialog, setShowFilterDialog] = useState(false)

  const loadAssessments = useCallback(async () => {
    try {
      setIsLoading(true)

      let payload = {
        ...filters,
        ...additionalFilters,
        organizationId: userCtx.organization_id,
        participantId: userCtx.participant_id,
        pageSize: filters.rows,
        pageNum: filters.page + 1,
      }

      const result = await assessmentsApiCompleteListPost(
        userCtx.tokenAssessments,
        payload
      )

      setDataset(result)
      setIsLoading(false)
    } catch (error) {
      const message = "Unable to load completed assessments"

      LogError(message, error)
      userCtx.setNotificationError(message)

      setDataset(undefined)
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters,
    additionalFilters,
    userCtx.participant_id,
    userCtx.organization_id,
    triggerRefresh,
  ])

  useEffect(() => {
    loadAssessments()
  }, [loadAssessments])

  const RenderActionBody = (rowData) => {
    let iconName = "visibility"
    let action = onView

    return (
      <div className={assessStyles.actionButtonContainer}>
        <Button
          type="button"
          onClick={() => {
            action(rowData.id)
          }}
          className={assessStyles.actionButtonPurple}
        >
          <span className={`material-icons`}>{iconName}</span>
        </Button>
      </div>
    )
  }

  const CompletedDateTemplate = (rowData) => {
    let date = rowData.completedDate

    if (!!date) {
      const dateFromIso = DateTime.fromISO(date, { zone: "utc" })

      date = dateFromIso.toLocal().toLocaleString()
    }

    return <>{date}</>
  }

  return (
    <>
      {showFilterDialog ? (
        <ParticipantAssessmentFilters
          filters={additionalFilters}
          onSetFilters={(newFilters) => {
            setAdditionalFilters(newFilters)
            setShowFilterDialog(false)
          }}
          onClose={() => {
            setShowFilterDialog(false)
          }}
        />
      ) : null}
      <div className={myStyles.completedLayout}>
        <div className={myStyles.completedLayoutHeader}></div>
        <div className={myStyles.completedLayoutFilters}>
          <Button
            className={`${myStyles.actionButton}`}
            onClick={() => {
              setShowFilterDialog(true)
            }}
          >
            <span className={`${myStyles.filterButtonText}`}>{`Filters${
              Object.keys(additionalFilters).length > 0
                ? ` [${Object.keys(additionalFilters).length}]`
                : ""
            }`}</span>
            <span className={`material-icons`}>{"tune"}</span>
          </Button>
        </div>
        <div className={myStyles.completedLayoutSort}>
          <Dropdown
            className={myStyles.completedComponent}
            value={filters.sortField}
            options={[
              { label: "Sort by: Name", value: "assessmentName" },
              { label: "Sort by: Description", value: "assessmentDescription" },
              { label: "Sort by: Score", value: "score" },
              { label: "Sort by: Completed date", value: "completedDate" },
            ]}
            optionLabel="label"
            optionValue="value"
            onChange={(e) => {
              setFilters({ ...filters, sortField: e.value })
            }}
          />
        </div>
        <div className={myStyles.completedLayoutBody}>
          {isLoading ? (
            <LoaderMedium />
          ) : (
            <>
              <DataTable
                value={dataset?.assessments || []}
                sortField={filters.sortField}
                sortOrder={filters.sortOrder === "a-z" ? 1 : -1}
                onSort={(sort) => {
                  const sortOrder = sort.sortOrder === 1 ? "a-z" : "z-a"
                  setFilters({
                    ...filters,
                    sortField: sort.sortField,
                    sortOrder: sortOrder,
                  })
                }}
                className={assessStyles.dataTable}
              >
                <Column
                  header={"Name"}
                  field={"assessmentName"}
                  sortable
                ></Column>
                <Column
                  header={"Description"}
                  field={"assessmentDescription"}
                  sortable
                ></Column>
                <Column header={"Score"} field={"score"} sortable></Column>
                <Column
                  header={"Completed date"}
                  body={CompletedDateTemplate}
                  field={"completedDate"}
                  sortable
                ></Column>
                <Column body={RenderActionBody} />
              </DataTable>
              <Paginator
                first={dataset ? (dataset?.pageNum - 1) * dataset?.pageSize : 0}
                rows={dataset?.pageSize || 0}
                totalRecords={dataset?.numTotal || 0}
                onPageChange={(setup) => {
                  setFilters({ ...filters, ...setup })
                }}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}
