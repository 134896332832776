import React from "react"
import { useContext, useEffect, useRef, useState } from "react"

// Context
import { UserContext } from "../../context/userContext"
import {
  declineParticipantAppointment,
  editParticipantAppointment,
  getAllMinifiedAppointments,
  getAvailabilities,
  sendParticipantAppointment,
} from "./AppointmentsApi"
import { Toast } from "primereact/toast"
import { Dialog } from "primereact/dialog"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import styles from "../../styles/Home-Staff.module.css"
import { TabView, TabPanel } from "primereact/tabview"
import "primeicons/primeicons.css"
import "primereact/resources/themes/bootstrap4-light-purple/theme.css"
import "primereact/resources/primereact.css"
import { Calendar } from "primereact/calendar"
import { useQuery } from "@apollo/client"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { Checkbox } from "primereact/checkbox"
import { useFormik } from "formik"
import { ToggleButton } from "primereact/togglebutton"
import { classNames } from "primereact/utils"
import {
  formatDate,
  timezones,
  durations,
  engagementChannels,
  isFormFieldValid,
  customStyles,
  formatOrgCalDate,
  reformatAppointmentRequest,
  getDaysArrayByMonth,
  getDateKey,
  getDateTimeValue,
  getDate,
  getLocalControllerDescription,
} from "../../components/Appointments/utils"
import "./lib/cron.js"

import { parseCronExpression } from "cron-schedule"
import { GET_PARTICIPANT, GET_SERVICES } from "./taskQueries"
import { DateTime } from "luxon"
import {
  applyAppointmentFilters,
  filterServiceTemplates,
} from "./utilsFilters.js"
import AppointmentsFormCron from "./AppointmentsFormCron.js"
import AppointmentFilters from "./AppointmentFilters.js"
import { LogError } from "../../helpers/logger.js"
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog"
import AppointmentsCalendarRecurringControllers from "./AppointmentsCalendarRecurringControllers.js"
import { generateFutureRecurringEventsForControllers } from "./utilsRecurringControllers.js"
import AppointmentStaffColumnBody from "./AppointmentsStaffColumnBody.js"

//PrimeReact
import { Tooltip } from "primereact/tooltip"

//Styles
import myStyles from "../../styles/appointments/Appointments.module.css"

export default function Appointments() {
  const user = useContext(UserContext)

  const [data, setData] = useState([])
  const [activeStaffSchedulingIndex, setActiveStaffSchedulingIndex] =
    useState(0)

  const [loadingData, setLoadingData] = useState(true)
  const [cachedPastAppointments, setCachedPastAppointments] = useState([])
  const [cachedUpcomingAppointments, setCachedUpcomingAppointments] = useState(
    []
  )
  const [cachedPendingAppointments, setCachedPendingAppointments] = useState([])
  const [
    cachedRecurringParticipantControllers,
    setCachedRecurringParticipantControllers,
  ] = useState([])
  const [pastAppointments, setPastAppointments] = useState([])
  const [upcomingAppointments, setUpcomingAppointments] = useState([])
  const [pendingAppointments, setPendingAppointments] = useState([])
  const [recurringParticipantControllers, setRecurringParticipantControllers] =
    useState([])
  const [activeAvailabilities, setActiveAvailabilities] = useState([])
  const [disabledDates, setDisabledDates] = useState([])
  const [availableTimes, setAvailableTimes] = useState([])
  const [pid, setPid] = useState(user.participant_alias)
  const [selectedDateView, setSelectedDateView] = useState(null)
  const toast = useRef(null)
  var icon = "calendar_month"
  const [show, setShow] = useState("")
  const [initPayload, setinitPayload] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [isCalendarView, setIsCalendarView] = useState("Calendar")
  const [date, setDate] = useState(new Date())
  const [filterDate, setFilterDate] = useState([
    DateTime.local().startOf("month").toJSDate(),
    DateTime.local().endOf("month").toJSDate(),
  ])
  const [selectedFilters, setSelectedFilters] = useState([])

  const [apptModalMode, setApptModalMode] = useState("Request Appointment")

  const [activeCron, setActiveCron] = useState(undefined)

  const [editingRulesForController, setEditingRulesForController] =
    useState(false)

  const [confirmModalIsOpen, setConfirmIsOpen] = useState(false)
  const [confirming, setConfirming] = useState({ mode: "", message: "" })

  const staffProfiles = useRef([])

  const [assignedStaffList, setAssignedStaffList] = useState([])
  const [assignedStaff, setAssignedStaff] = useState(undefined)
  const [services, setServices] = useState([])

  useEffect(() => {
    const firstDayOfMonth = DateTime.local().startOf("month").toJSDate()
    setSelectedDateViewValue(firstDayOfMonth)
  }, [])

  useEffect(() => {
    if (user.staff_assigment_list.length > 0) {
      const assignedStaffListing = user.staff_assigment_list.map((staff) => {
        return {
          ...staff,
          displayName: `${staff.staff_name_first} ${staff.staff_name_last}`,
        }
      })

      if (assignedStaffListing.length > 0) {
        staffProfiles.current = assignedStaffListing.filter(
          (staff) => staff.staff_id !== "-4"
        )

        setAssignedStaff(assignedStaffListing[0])
        setAssignedStaffList(assignedStaffListing)
      } else {
        setAssignedStaff(undefined)
        setAssignedStaffList([])
      }
    } else {
      setAssignedStaff(undefined)
      setAssignedStaffList([])
    }
  }, [user.staff_assigment_list])

  const appointmentStyles = {
    appointmentContainer: {
      padding: "20px",
    },
  }

  useEffect(() => {
    loadAvailabilities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedStaff])

  const defaultInitialValues = {
    id: 0,
    serviceName: "Full Intake",
    duration: { code: "30m", name: "30 minutes" },
    engagementChannel: "Telephone",
    timezone: {
      code: DateTime.local().toFormat("z"),
      name: timezones.find((x) => x.code == DateTime.local().toFormat("z"))
        ? timezones.find((x) => x.code == DateTime.local().toFormat("z")).name
        : timezones[0].name,
    },
    startDate: new Date(),
    endDate: new Date(),
    selectedTime: new Date(),
    isRequest: false,
    isRecurring: false,
    cron: null,
    cronDescription: "",
  }

  const [currentAppointmentObject, setCurrentAppointmentObject] =
    useState(defaultInitialValues)

  const activeAppointment = useFormik({
    initialValues: defaultInitialValues,
    validate: (data) => {
      let errors = {}
      if (!data.startDate) {
        errors.startDate = "Date is required."
      }

      if (!data.selectedTime) {
        errors.selectedTime = "Time is required."
      }

      if (!data.participant) {
        errors.participant = "Participant is required."
      }

      return errors
    },
    onSubmit: (data) => {
      activeAppointment.resetForm()
      setCurrentAppointmentObject(defaultInitialValues)
    },
  })

  useQuery(GET_PARTICIPANT, {
    variables: {
      participant_id: user.participant_id,
      organization_id: user.organization_id,
    },
    onCompleted: ({ participant }) => {
      setPid(participant.participant_alias)
      user.organization_alias = participant.organization_alias
      activeAppointment.setFieldValue("participant", {
        code: participant.participant_alias,
        name:
          participant.participant_name_first +
          " " +
          participant.participant_name_last,
        email: participant.participant_email_account,
        phone: participant.participant_phone_primary,
        alias: participant.participant_id,
      })
    },
    onError: ({ err }) => {
      console.log(err)
    },
  })

  const { refetch: loadFromList } = useQuery(GET_SERVICES, {
    fetchPolicy: "network-only",
    variables: {
      organization_id: user.organization_id,
      template_type: "session_module",
    },
    onError: (e) => {
      user.setNotificationError("Unable to load service types for appointments")
    },
    onCompleted: (data) => {
      setServices(
        filterServiceTemplates(data.getTemplatesByOrganization).map(
          (x) => x.template_title
        )
      )
    },
  })

  async function updateList(newDate) {
    setDate(newDate)

    const endOfDay = DateTime.fromJSDate(newDate).endOf("day")
    let filtered = upcomingAppointments.filter((x) => {
      let startUnix = getDate(x.startDateTime).toUnixInteger()
      let selectedUnix = DateTime.fromJSDate(newDate).toUnixInteger()
      return startUnix > selectedUnix && startUnix < endOfDay.toUnixInteger()
    })

    setData(filtered)
    activeAppointment.setFieldValue("startDate", newDate)
  }

  async function confirmAction() {
    if (confirming.mode === "cancelAppointment") {
      requestNewAppointment(true, true)
    }
  }

  async function getParticipantAppointments() {
    const fromDate = DateTime.fromJSDate(selectedDateView.from)
    const toDate = DateTime.fromJSDate(selectedDateView.to).plus({
      days: 14,
    })

    let newPastAppointments = []
    let newUpcomingAppointments = []

    let res3 = await getAllMinifiedAppointments(
      user.tokenAppointments,
      null,
      fromDate.toFormat("yyyy-MM-dd"),
      toDate.toFormat("yyyy-MM-dd"),
      pid
    )
    if (res3.successful && res3.data.length > 0) {
      newPastAppointments = res3.data
        .map((x) => {
          x = formatDate(x)
          return x
        })
        .filter(
          (x) =>
            getDate(x.startDateTime).toUnixInteger() <
            DateTime.local().toUnixInteger()
        )
        .reverse()

      newUpcomingAppointments = res3.data

      setPastAppointments([])
      setCachedPastAppointments(newPastAppointments)
    } else if (!res3.successful) {
      LogError("Unable to retrieve appointment data", res3)
    }

    await getRecurringParticipantAppointments(newUpcomingAppointments)
  }

  async function getPendingAppointments() {
    var start = DateTime.fromJSDate(selectedDateView.from).minus({ days: 120 })
    var end = DateTime.fromJSDate(selectedDateView.to).plus({ days: 120 })

    let res4 = await getAllMinifiedAppointments(
      user.tokenAppointments,
      null,
      start.toFormat("yyyy-MM-dd"),
      end.toFormat("yyyy-MM-dd"),
      pid,
      false,
      true
    )
    if (res4.successful) {
      const newPendingAppointments = res4.data.map((x) => {
        x = formatDate(x)
        x.staff =
          assignedStaff.staff_name_first + " " + assignedStaff.staff_name_last
        return x
      })

      setPendingAppointments([])
      setCachedPendingAppointments(newPendingAppointments)
    }
  }

  async function getRecurringParticipantAppointments(upcoming) {
    var start = DateTime.fromJSDate(selectedDateView.from)
    var end = DateTime.fromJSDate(selectedDateView.to).plus({
      days: 14,
    })

    let recurringAppts = [] //Recurring controllers
    let generated = [] //upcoming appointments generated for Recurring controller

    var res = await getAllMinifiedAppointments(
      user.tokenAppointments,
      null,
      start.toFormat("yyyy-MM-dd"),
      end.toFormat("yyyy-MM-dd"),
      pid,
      true,
      null,
      null
    )
    if (res.successful) {
      recurringAppts = res.data
      for (var controller of recurringAppts) {
        controller.isRecurringController = true

        let localFrequency = getLocalControllerDescription(
          controller.frequency,
          getDate(controller.startDateTime)
        )

        let frequency = controller.frequency.split(" | ")
        let newFreq

        if (
          frequency[1] === "Daily" &&
          frequency[frequency.length - 1].includes(":")
        ) {
          newFreq = frequency[1]
        } else {
          newFreq = frequency[1] + " | " + frequency[frequency.length - 1]
        }

        controller.newFreq = newFreq
        controller.frequencyPeriod = frequency[1]
        controller.localFrequency = localFrequency

        controller = formatDate(controller)

        const futureAppointments = generateFutureRecurringEventsForControllers(
          [controller],
          upcoming,
          selectedDateView?.from,
          selectedDateView?.to
        )

        for (var futureDate of futureAppointments) {
          //delete obj.startDateTime
          generated.push({
            ...futureDate,
            isRecurringController: false,
            cron: null,
            id: `recurring_${start.toFormat("mmddyy")}_${controller.id}`,
          })
        }
      }
    } else {
      LogError("Unable to retrieve recurring appointments", res)
    }

    const filteredUpcoming = upcoming
      .map((x) => {
        x = formatDate(x)
        return x
      })
      .filter(
        (x) =>
          getDate(x.startDateTime).toUnixInteger() >
          DateTime.local().toUnixInteger()
      )

    const newUpcomingAppointments = [...filteredUpcoming, ...generated]

    setRecurringParticipantControllers([])
    setCachedRecurringParticipantControllers(recurringAppts)

    setUpcomingAppointments([])
    setCachedUpcomingAppointments(newUpcomingAppointments)
  }

  async function loadAvailabilities() {
    if (assignedStaff) {
      let res5 = await getAvailabilities(
        user.tokenAppointments,
        assignedStaff.staff_email_address
      )
      if (res5.data && res5.data.length > 0) {
        setActiveAvailabilities(
          res5.data.filter((x) => {
            if (x.isRecurring) x.individualStartDate = x.recurringStartDate
            return (
              DateTime.fromFormat(
                x.individualStartDate,
                getDateKey(x.individualStartDate)
              ) > DateTime.local() ||
              (x.isRecurring &&
                DateTime.fromFormat(
                  x.recurringEndDate,
                  getDateKey(x.recurringEndDate)
                ) > DateTime.local())
            )
          })
        )
      }
    } else {
      setActiveAvailabilities([])
    }
  }

  function openEditModal(data, requestingExisting) {
    let cron = ""
    let cronDescription = ""
    let cronPeriod = ""

    if (data.isRecurring || data?.cron) {
      cron = data?.cron
      cronDescription = data?.frequency
      cronPeriod = data.frequencyPeriod
    }

    setActiveCron({
      cronValue: cron,
      cronDescription: cronDescription,
      period: cronPeriod,
    })

    activeAppointment.resetForm()

    setApptModalMode(
      requestingExisting ? "Request Appointment Change" : "Edit Appointment"
    )
    let payload = reformatAppointmentRequest(
      activeAppointment,
      user,
      data,
      true,
      true,
      activeAvailabilities.length > 0,
      {
        cronValue: cron,
        cronDescription: cronDescription,
      }
    )
    activeAppointment.setValues(payload)
    setCurrentAppointmentObject(payload)

    if (requestingExisting) activeAppointment.setFieldValue("isRequest", true)

    onDurationSelect({ value: payload.duration })

    if (activeAvailabilities && activeAvailabilities.length > 0) {
      var selectedDate = activeAvailabilities.filter((x) => {
        if (!x.isRecurring)
          return (
            DateTime.fromJSDate(payload.startDate).toFormat("MM/dd/yyyy") ==
            x.times[0].toFormat("MM/dd/yyyy")
          )
        else return false
      })[0]

      setAvailableTimes(selectedDate.times.map((x) => x.toFormat("hh:mm a")))
    }
    setIsOpen(true)
  }

  function decideTimeSelection(isRecurring) {
    if (activeAvailabilities.length < 1) {
      if (isRecurring) {
        return (
          <div style={{ ...customStyles.inputField, width: "100%" }}>
            <label htmlFor="selectedTime">Start Time</label>
            <br />
            <Calendar
              id="selectedTime"
              style={customStyles.inputStyle}
              onChange={activeAppointment.handleChange}
              value={activeAppointment.values.selectedTime}
              className={classNames({
                "p-invalid": isFormFieldValid(
                  activeAppointment,
                  "selectedTime"
                ),
              })}
              timeOnly
              hourFormat="12"
            />
          </div>
        )
      } else {
        return (
          <div style={customStyles.inputField}>
            <label htmlFor="selectedTime">Appointment Time</label>
            <br />
            <Calendar
              id="selectedTime"
              style={customStyles.inputStyle}
              onChange={activeAppointment.handleChange}
              value={activeAppointment.values.selectedTime}
              className={classNames({
                "p-invalid": isFormFieldValid(
                  activeAppointment,
                  "selectedTime"
                ),
              })}
              timeOnly
              hourFormat="12"
            />
          </div>
        )
      }
    } else {
      return (
        <div style={customStyles.inputField}>
          <label htmlFor="selectedTime">Appointment Time</label>
          <br />
          <Dropdown
            id="selectedTime"
            style={customStyles.inputStyle}
            onChange={activeAppointment.handleChange}
            options={availableTimes}
            value={activeAppointment.values.selectedTime}
            className={classNames({
              "p-invalid": isFormFieldValid(activeAppointment, "selectedTime"),
            })}
          />
        </div>
      )
    }
  }

  function layoutRecurringAvailability(recurrings, duration) {
    let availabilities = []
    for (var [i, availableTime] of recurrings.entries()) {
      let recurringStartDate = DateTime.fromFormat(
        availableTime.recurringStartDate,
        getDateKey(availableTime.recurringStartDate)
      )
      let recurringEndDate = DateTime.fromFormat(
        availableTime.recurringEndDate,
        getDateKey(availableTime.recurringEndDate)
      )

      let cron = parseCronExpression(availableTime.cron)

      let dates = cron
        .getNextDates(30, recurringStartDate.toJSDate())
        .filter((x) => recurringEndDate > DateTime.fromJSDate(x))

      for (var date of dates) {
        let endTime = availableTime.recurringEndTime.split(":")

        let startDate = DateTime.fromJSDate(date)
        let endDate = DateTime.fromFormat(
          DateTime.fromJSDate(date).toFormat("MM/dd/yyyy") + " " + endTime,
          "MM/dd/yyyy hh:mm a"
        )

        let availableTimes = [DateTime.fromMillis(startDate.toUnixInteger())]

        let nextDate = startDate.plus({ minutes: duration })
        while (nextDate <= endDate) {
          availableTimes.push(DateTime.fromMillis(nextDate.toUnixInteger()))

          nextDate = nextDate.plus({ minutes: duration })
        }

        let newEntry = { ...availableTime }
        newEntry.times = availableTimes

        newEntry.isRecurring = false
        newEntry.individualStartDate = DateTime.fromMillis(
          availableTimes[0]
        ).toString()
        newEntry.individualEndDate = DateTime.fromMillis(
          availableTimes[availableTimes.length - 1]
        ).toString()

        availabilities.push(newEntry)
      }

      //availableTime.times = availableTimes
    }

    return availabilities
  }

  function onDurationSelect(ev) {
    let duration = ev.value.mins
    if (duration) {
      activeAppointment.setFieldValue("duration", ev.value)

      let recurringAvails = layoutRecurringAvailability(
        activeAvailabilities.filter((x) => x.isRecurring),
        duration
      )
      let avails = [...activeAvailabilities, ...recurringAvails]
      setActiveAvailabilities(avails)

      let individuals = avails
        .filter((x) => !x.isRecurring)
        .sort((a, b) => {
          if (
            DateTime.fromFormat(
              a.individualStartDate,
              getDateKey(a.individualStartDate)
            ) <
            DateTime.fromFormat(
              b.individualStartDate,
              getDateKey(b.individualStartDate)
            )
          ) {
            return -1
          }
          if (
            DateTime.fromFormat(
              a.individualStartDate,
              getDateKey(a.individualStartDate)
            ) >
            DateTime.fromFormat(
              b.individualStartDate,
              getDateKey(b.individualStartDate)
            )
          ) {
            return 1
          }
          // a must be equal to b
          return 0
        })

      let minDate, maxDate
      let days = getDaysArrayByMonth(
        DateTime.fromJSDate(selectedDateView?.from)
      )
      for (var [i, availableTime] of individuals.entries()) {
        let startDate = DateTime.fromFormat(
          availableTime.individualStartDate,
          getDateKey(availableTime.individualStartDate)
        )
        let endDate = DateTime.fromFormat(
          availableTime.individualEndDate,
          getDateKey(availableTime.individualEndDate)
        )
        if (i === 0) {
          minDate = startDate
        }
        if (i == individuals.length - 1) {
          maxDate = endDate
        }

        if (!availableTime.times) {
          let availableTimes = [DateTime.fromMillis(startDate.toUnixInteger())]

          let nextDate = startDate.plus({ minutes: duration })
          while (nextDate <= endDate) {
            availableTimes.push(DateTime.fromMillis(nextDate.toUnixInteger()))

            nextDate = nextDate.plus({ minutes: duration })
          }

          availableTime.times = availableTimes
        }
      }

      days = days
        .filter((x) => {
          let isAvailable = individuals.findIndex((y) => {
            if (
              DateTime.fromFormat(
                y.individualStartDate,
                getDateKey(y.individualStartDate)
              ).toFormat("MM/dd") != x.toFormat("MM/dd")
            ) {
              return false
            } else {
              activeAppointment.setFieldValue(
                "startDate",
                DateTime.fromFormat(
                  y.individualStartDate,
                  getDateKey(y.individualStartDate)
                ).toJSDate()
              )
              return true
            }
          })
          return isAvailable === 1
        })
        .map((x) => new Date(x.toFormat("MM/dd/yyyy")))
      setDisabledDates(days)
    }
  }

  function onDateSelect(ev) {
    activeAppointment.setFieldValue("startDate", ev.value)
    if (activeAvailabilities && activeAvailabilities.length > 0) {
      var selectedDate = activeAvailabilities.filter((x) => {
        if (!x.isRecurring)
          return (
            DateTime.fromJSDate(ev.value).toFormat("MM/dd/yyyy") ==
            x.times[0].toFormat("MM/dd/yyyy")
          )
        else return false
      })[0]

      setAvailableTimes(selectedDate.times.map((x) => x.toFormat("hh:mm a")))
    }
  }

  async function getData() {
    if (selectedDateView && pid) {
      setLoadingData(true)

      setActiveAvailabilities([])
      setUpcomingAppointments([])
      setCachedUpcomingAppointments([])
      setRecurringParticipantControllers([])
      setCachedRecurringParticipantControllers([])
      setPastAppointments([])
      setCachedPastAppointments([])

      await getParticipantAppointments()
      await getPendingAppointments()
      await loadAvailabilities()
      updateList(date)
      filterAppointments()

      setLoadingData(false)
    }
  }

  useEffect(() => {
    if (!loadingData && selectedDateView) {
      filterAppointments()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingData])

  useEffect(() => {
    // Update the document title using the browser API

    if (pid !== undefined) {
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pid])

  useEffect(() => {
    if (activeAppointment.values.duration.code)
      onDurationSelect({ value: activeAppointment.values.duration })

    if (!!selectedDateView) {
      setFilterDate([selectedDateView.from, selectedDateView.to])

      if (
        user.participant_alias !== undefined &&
        assignedStaffList.length > 0
      ) {
        getData()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateView])

  useEffect(() => {
    if (
      cachedPastAppointments ||
      cachedUpcomingAppointments ||
      cachedPendingAppointments ||
      cachedRecurringParticipantControllers
    ) {
      filterAppointments()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters, filterDate])

  useEffect(() => {
    updateList(date)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upcomingAppointments])

  function filterAppointments() {
    const getFilteredList = (list, isRecurring, ignoreDateFilter) => {
      return applyAppointmentFilters(
        list,
        selectedFilters,
        services,
        filterDate,
        ignoreDateFilter || isCalendarView,
        isRecurring
      )
    }

    const filteredUpcomingAppointments = getFilteredList(
      cachedUpcomingAppointments
    )
    setUpcomingAppointments(filteredUpcomingAppointments)

    const filteredPastAppointments = getFilteredList(cachedPastAppointments)
    setPastAppointments(filteredPastAppointments)

    const filteredPendingAppointments = getFilteredList(
      cachedPendingAppointments
    )
    setPendingAppointments(filteredPendingAppointments)

    const filteredControllers = getFilteredList(
      cachedRecurringParticipantControllers,
      true
    )
    setRecurringParticipantControllers(filteredControllers)
  }

  async function requestNewAppointment(
    requestingCancellation,
    toggleConfirmed = false
  ) {
    if (requestingCancellation) {
      if (toggleConfirmed === false) {
        setConfirming({
          mode: "cancelAppointment",
          message:
            "Are you sure you want to request to cancel this appointment? ",
        })
        setConfirmIsOpen(true)
        return
      } else {
        setConfirmIsOpen(false)
      }
    }

    let cron = activeAppointment.values.isRecurring
      ? activeCron.cronValue
      : null

    let cronPeriod = activeAppointment.values.isRecurring
      ? activeCron.period
      : null

    let payload = reformatAppointmentRequest(
      activeAppointment,
      user,
      null,
      false,
      apptModalMode.includes("Edit") || apptModalMode.includes("Change"),
      activeAvailabilities.length > 0,
      {
        cronValue: cron,
        cronDescription: "",
      },
      cronPeriod
    )

    if (payload) {
      payload.id = payload.id.toString()
      try {
        let res
        if (
          apptModalMode.includes("Edit") ||
          apptModalMode.includes("Change")
        ) {
          if (apptModalMode.includes("Change")) {
            if (requestingCancellation) payload.type = "CANCELLING"
            else payload.type = "RESCHEDULE"

            payload.status = "ACTIVE"
          }

          res = await editParticipantAppointment(
            user.tokenAppointments,
            payload
          )
        } else
          res = await sendParticipantAppointment(
            user.tokenAppointments,
            payload
          )

        if (res.successful) {
          toast.current.show({
            severity: "success",
            detail: `Successfully requested appointment`,
            life: 3000,
          })
          setIsOpen(false)
          activeAppointment.resetForm()
          setCurrentAppointmentObject(defaultInitialValues)
          setinitPayload({})
          getData()
        } else {
          user.setNotificationError(res.error)
        }
      } catch (e) {
        toast.current.show({
          severity: "error",
          detail: e.message,
          life: 9000,
        })
      }
    }
  }

  function formatRequestActions(rowData) {
    let disableButton = rowData.status === "BOOKED.PENDING_CHANGE"

    if (!disableButton && rowData.id.includes("recurring_")) {
      //Look for active pending requests for this calcualted recurring entry
      const pendingRequest = pendingAppointments.filter(
        (x) =>
          x.appointmentId === rowData.id &&
          x.type !== "DECLINED" &&
          x.type !== "CANCELLED.DENIED"
      )
      disableButton = pendingRequest.length > 0
    }

    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <button
          type="submit"
          className="btn-sm"
          style={{ marginRight: "5px" }}
          onClick={() => {
            openEditModal(rowData, true)
          }}
          disabled={disableButton}
        >
          Request Change
        </button>
      </div>
    )
  }

  function renderFooter() {
    const canRequest = canRequestNew()

    return (
      <>
        <Tooltip target=".footer-tt" className={myStyles.tooltip} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          {!canRequest.allow ? (
            <span
              className={`${myStyles.tooltipIcon} material-icons footer-tt`}
              data-pr-tooltip={canRequest.reason}
              data-pr-position="left"
            >
              {"info"}
            </span>
          ) : null}

          <button
            type="submit"
            className="btn"
            onClick={() => {
              activeAppointment.resetForm()

              const defaultStartDate = DateTime.fromJSDate(
                activeAppointment.values.startDate
              )
              setActiveCron({
                cronValue: `${defaultStartDate.minute} ${defaultStartDate.hour} * * *`,
                cronDescription: "",
                period: "Daily",
              })

              setCurrentAppointmentObject(defaultInitialValues)
              setIsOpen(true)
              setApptModalMode("Request Appointment")
            }}
            disabled={!canRequest.allow}
          >
            Request New Appointment
          </button>
        </div>
      </>
    )
  }

  const confirmDelete = (id) => {
    confirmDialog({
      message: "Are you sure you want to delete this request?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        setIsOpen(false)
        setinitPayload({})
        deleteRequest(id).then(() => {
          getData()
        })
      },
      reject: () => {},
    })
  }

  async function deleteRequest(id) {
    let res = await declineParticipantAppointment(user.tokenAppointments, id)

    if (res.successful) {
      toast.current.show({
        severity: "success",
        detail: "Appointment request deleted",
        life: 3000,
      })
      setPendingAppointments([])
      await getPendingAppointments()
    } else {
      user.setNotificationError("Unable to delete appointment request")
      LogError("Unable to delete appointment request", res)
    }
  }

  useEffect(() => {
    if (user.toggleAppointment.toggle) {
      setShow("true")
    } else {
      setShow("")
    }
  }, [user.toggleAppointment])

  useEffect(() => {
    if (modalIsOpen) {
      setinitPayload(activeAppointment.values)
    }
  }, [modalIsOpen])

  const setSelectedDateViewValue = (fromDate, toDate) => {
    if (!fromDate) {
      fromDate = DateTime.local().startOf("day").toJSDate()
    } else {
      fromDate = DateTime.fromJSDate(fromDate).startOf("day").toJSDate()
    }

    if (!toDate) {
      //If no end date is specified, set the date to the end of the month
      toDate = DateTime.fromJSDate(fromDate).endOf("month").toJSDate()
    }

    if (date < fromDate || date > toDate) {
      const now = DateTime.local().startOf("day").toJSDate()
      if (now > fromDate && now < toDate) {
        updateList(now)
      } else {
        updateList(fromDate)
      }
    }

    setSelectedDateView({ from: fromDate, to: toDate })
  }

  const formatApptActions = (rowData) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <button
          type="submit"
          className="btn-sm btn-info"
          style={{ background: "#00508e", marginRight: "5px" }}
          onClick={() => {
            openEditModal(rowData)
          }}
          disabled={rowData.type !== "NEW"}
        >
          Edit
        </button>
        <button
          type="submit"
          className="btn-sm btn-danger"
          onClick={() => confirmDelete(rowData.id)}
        >
          Delete
        </button>
      </div>
    )
  }

  function toggleRecurringAppt() {
    if (!activeAppointment.values.isRecurring) {
      return (
        <>
          <div style={customStyles.inputField}>
            <label htmlFor="startDate">Appointment Date</label>
            <br />
            <Calendar
              id="startDate"
              style={customStyles.inputStyle}
              viewDate={selectedDateView?.from}
              onViewDateChange={(ev) => setSelectedDateViewValue(ev.value)}
              onChange={onDateSelect}
              value={activeAppointment.values.startDate}
              disabledDates={disabledDates}
              className={classNames({
                "p-invalid": isFormFieldValid(activeAppointment, "startDate"),
              })}
            />
          </div>
          {decideTimeSelection()}
        </>
      )
    } else {
      return (
        <>
          <div style={customStyles.inputField}>
            <label htmlFor="startDate">Start Date</label>
            <br />
            <Calendar
              id="startDate"
              style={customStyles.inputStyle}
              onChange={activeAppointment.handleChange}
              value={activeAppointment.values.startDate}
              className={classNames({
                "p-invalid": isFormFieldValid(activeAppointment, "startDate"),
              })}
            />
          </div>
          <div style={customStyles.inputField}>
            <label htmlFor="startTime">End Date</label>
            <br />
            <Calendar
              id="endDate"
              style={customStyles.inputStyle}
              onChange={activeAppointment.handleChange}
              value={activeAppointment.values.endDate}
              className={classNames({
                "p-invalid": isFormFieldValid(activeAppointment, "endDate"),
              })}
            />
          </div>
          <div style={{ ...customStyles.inputField, width: "95%" }}>
            <div
              hidden={
                apptModalMode.includes("Edit") && !editingRulesForController
              }
            >
              <AppointmentsFormCron
                cronValues={activeCron}
                startTime={activeAppointment?.values?.selectedTime}
                onChangeCronValues={(cron) => {
                  setActiveCron(cron)
                }}
              />
              <br />
              {decideTimeSelection(true)}
            </div>
            <div
              hidden={
                apptModalMode.includes("Request Appointment") ||
                editingRulesForController ||
                activeAppointment.values.isRecurring === false
              }
            >
              <div style={{ ...customStyles.inputField, width: "95%" }}>
                <label htmlFor="">Recurring Rules:</label>
                <br />
                <span>{activeAppointment.values.cronDescription}</span> |{" "}
                <button
                  type="button"
                  className="btn-sm"
                  onClick={() => setEditingRulesForController(true)}
                >
                  Change
                </button>
                <br />
              </div>
            </div>
          </div>
        </>
      )
    }
  }

  function EditRecurringAppointmentsColumnBody(rowData) {
    const onClick = () => {
      const controller = recurringParticipantControllers.filter(
        (x) => x.id.toString() === rowData.id.toString()
      )
      if (controller.length > 0) {
        openEditModal(controller[0], true)
      } else {
        user.setNotificationError(
          "Unable to find the recurring appointment controller for this instance"
        )
      }
    }

    return (
      <button
        className="btn"
        onClick={onClick}
        style={{ width: "80px", paddingLeft: "12px" }}
        disabled={rowData.status === "BOOKED.PENDING_CHANGE"}
      >
        Request change
      </button>
    )
  }

  const canRequestNew = () => {
    let reason = ""
    //Disable new appointment requests for disabled participants
    let canRequest = !user.participant_membership_status
      .toLowerCase()
      .includes("inactive")

    if (canRequest && user.staff_assigment_list?.length > 0) {
      //If this participant is unassigned, dont allow new requests
      canRequest = user.staff_assigment_list[0].staff_id !== "-4"
      reason = !canRequest
        ? "No staff member has been assigned to this profile"
        : reason
    } else {
      canRequest = false
      reason = "Your account is inactive"
    }

    return { allow: canRequest, reason: reason }
  }

  return (
    <>
      <Toast ref={toast} position="top-right" />
      <ConfirmDialog />
      <Dialog
        header={() => (
          <div
            style={{
              paddingLeft: "10px",
            }}
          >
            <h4
              style={{
                margin: 0,
                marginBottom: "15px",
                color: "#757575",
                fontWeight: "300",
                fontSize: "20px",
              }}
            >
              Appointments
            </h4>
          </div>
        )}
        visible={show !== ""}
        onHide={() => {
          setShow("")
        }}
        style={{ width: "1150px", height: "1000px" }}
        contentStyle={{ padding: 0 }}
        headerStyle={{ paddingBottom: "0px" }}
        footer={renderFooter}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
            paddingBottom: "5px",
            alignItems: "baseline",
          }}
        >
          <ToggleButton
            onLabel="Calendar View"
            offLabel="List View"
            onIcon="pi pi-calendar"
            offIcon="pi pi-align-justify"
            checked={isCalendarView}
            onChange={(e) => {
              setIsCalendarView(e.value)

              if (e.value) {
                setSelectedDateViewValue(
                  DateTime.now().startOf("month").toJSDate()
                )
              } else {
                const from = DateTime.fromJSDate(selectedDateView.from).plus({
                  days: -30,
                })
                const to = DateTime.fromJSDate(selectedDateView.to).plus({
                  days: 30,
                })

                setSelectedDateViewValue(from.toJSDate(), to.toJSDate())
              }
            }}
          />
          <AppointmentFilters
            hideDateSelection={isCalendarView}
            dateRange={filterDate}
            onChangeDateRange={(dateRange) => {
              setFilterDate(dateRange)

              if (!dateRange.includes(null)) {
                setSelectedDateViewValue(dateRange[0], dateRange[1])
              }
            }}
            filterValues={selectedFilters}
            onChangeFilterValues={(value) => {
              setSelectedFilters(value)
            }}
            services={services}
          />
        </div>
        <div style={{ padding: "20px" }} hidden={!isCalendarView}>
          <Calendar
            inline
            value={date}
            viewDate={selectedDateView?.from}
            onViewDateChange={(ev) => setSelectedDateViewValue(ev.value)}
            onChange={(e) => updateList(e.value)}
            style={{
              marginTop: "5px",
              width: "100%",
              pDatepickerCurrentDay: { background: "red" },
            }}
          ></Calendar>
          <div style={{ display: "flex" }}>
            <AppointmentsCalendarRecurringControllers
              controllers={recurringParticipantControllers}
              onEditController={(controller) => {
                openEditModal(controller, true)
              }}
            />
            <div
              style={{
                border: "2px solid #8C28E4",
                width: "70%",
                borderBottomRightRadius: "12px",
                height: "300px",
                padding: "10px 30px 30px 30px",
                fontFamily: "Lato",
                overflow: "auto",
              }}
            >
              <h3>Appointments</h3>
              <br />
              {data
                .filter((x) => {
                  let startUnix = getDate(x.startDateTime).toUnixInteger()
                  let selectedUnix = DateTime.fromJSDate(date).toUnixInteger()
                  return (
                    startUnix > selectedUnix &&
                    startUnix <
                      DateTime.fromJSDate(date)
                        .plus({ days: 1 })
                        .toUnixInteger()
                  )
                })
                .map((obj, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "20px",
                      }}
                    >
                      <div className="">
                        <b style={{ color: "#666666" }}>
                          {getDate(obj.startDateTime).toFormat("h:mm a")}
                        </b>
                        <br />
                        <span style={{ color: "#666666" }}>
                          {" "}
                          {obj.engagementType + " with "}
                          <span style={{ color: "#8C28E4" }}>{obj.staff}</span>
                        </span>
                      </div>

                      <button
                        type="submit"
                        className="btn-sm"
                        style={{ display: "block" }}
                        onClick={() => {
                          openEditModal(obj, true)
                        }}
                        disabled={obj.status === "BOOKED.PENDING_CHANGE"}
                      >
                        Request Change
                      </button>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
        <div hidden={isCalendarView}>
          <TabView
            activeIndex={activeStaffSchedulingIndex}
            onTabChange={(e) => setActiveStaffSchedulingIndex(e.index)}
            panelContainerStyle={{ background: "rgba(0,0,0,0)", padding: 0 }}
          >
            <TabPanel header="Pending Appointments">
              <DataTable
                value={pendingAppointments.filter(
                  (x) =>
                    x.type !== "DECLINED" &&
                    x.type !== "BOOKED" &&
                    x.type !== "CANCELLED" &&
                    x.type !== "CANCELLED.DENIED"
                )}
                sortField="startDateTime"
                sortOrder={1}
              >
                <Column
                  field="startDateTime"
                  sortable
                  header="Date"
                  body={formatOrgCalDate}
                />
                <Column
                  field="engagementType"
                  sortable
                  header="Engagement Type"
                />
                <Column
                  field="engagementChannel"
                  sortable
                  bodyStyle={{ textTransform: "capitalize" }}
                  header="Engagement Channel"
                  body={(rowData) => {
                    if (rowData.cron == null) {
                      return rowData.engagementChannel
                    } else {
                      return rowData.engagementChannel + " | Recurring"
                    }
                  }}
                />
                <Column
                  field="scheduledTime"
                  sortable
                  header="Scheduled Time"
                />
                <Column
                  field="staff"
                  sortable
                  header="Staff"
                  body={(rowData) => (
                    <AppointmentStaffColumnBody
                      rowData={rowData}
                      staffList={staffProfiles.current}
                      onAddStaff={(profile) => {
                        staffProfiles.current = [
                          ...staffProfiles.current,
                          profile,
                        ]
                      }}
                    />
                  )}
                />
                <Column field="type" sortable header="Type" />
                <Column
                  field="action"
                  header="Action"
                  body={formatApptActions}
                />
              </DataTable>
            </TabPanel>
            <TabPanel header="Declined Appointments">
              <DataTable
                value={pendingAppointments.filter(
                  (x) => x.type === "DECLINED" || x.type !== "CANCELLED.DENIED"
                )}
                sortField="startDateTime"
                sortOrder={1}
              >
                <Column
                  field="startDateTime"
                  sortable
                  header="Date"
                  body={formatOrgCalDate}
                />
                <Column
                  field="engagementType"
                  sortable
                  header="Engagement Type"
                />
                <Column
                  field="engagementChannel"
                  sortable
                  bodyStyle={{ textTransform: "capitalize" }}
                  header="Engagement Channel"
                />
                <Column
                  field="scheduledTime"
                  sortable
                  header="Scheduled Time"
                />
                <Column field="staff" sortable header="Staff" />
              </DataTable>
            </TabPanel>
            <TabPanel header="Past Appointments">
              <DataTable
                value={pastAppointments}
                sortField="startDateTime"
                sortOrder={1}
              >
                <Column
                  field="startDateTime"
                  sortable
                  header="Date"
                  body={formatOrgCalDate}
                />
                <Column
                  field="engagementType"
                  sortable
                  header="Engagement Type"
                />
                <Column
                  field="engagementChannel"
                  sortable
                  bodyStyle={{ textTransform: "capitalize" }}
                  header="Engagement Channel"
                />
                <Column
                  field="scheduledTime"
                  sortable
                  header="Scheduled Time"
                />
                <Column field="staff" sortable header="Staff" />
              </DataTable>
            </TabPanel>
            <TabPanel header="Upcoming Appointments">
              <DataTable
                value={upcomingAppointments}
                sortField="startDateTime"
                sortOrder={1}
              >
                <Column
                  field="startDateTime"
                  sortable
                  header="Date"
                  body={formatOrgCalDate}
                />
                <Column
                  field="engagementType"
                  sortable
                  header="Engagement Type"
                />
                <Column
                  field="engagementChannel"
                  sortable
                  bodyStyle={{ textTransform: "capitalize" }}
                  header="Engagement Channel"
                />
                <Column
                  field="scheduledTime"
                  sortable
                  header="Scheduled Time"
                />
                <Column field="staff" sortable header="Staff" />
                <Column
                  field="action"
                  header="Action"
                  body={formatRequestActions}
                />
              </DataTable>
            </TabPanel>
            <TabPanel header="Scheduled Recurring Appointments">
              <DataTable value={recurringParticipantControllers}>
                <Column field="newFreq" sortable header="Date" />
                <Column
                  field="engagementType"
                  sortable
                  header="Engagement Type"
                />
                <Column
                  field="engagementChannel"
                  sortable
                  bodyStyle={{ textTransform: "capitalize" }}
                  header="Engagement Channel"
                />
                <Column
                  sortable
                  header="Range"
                  body={(obj) => {
                    const startDate = getDateTimeValue(obj.startDate)
                    const endDate = getDateTimeValue(obj.endDate)

                    return (
                      startDate.toFormat("MM/dd/yyyy") +
                      " - " +
                      endDate.toFormat("MM/dd/yyyy")
                    )
                  }}
                />
                <Column field="staff" sortable header="Staff" />
                <Column body={EditRecurringAppointmentsColumnBody} />
              </DataTable>
            </TabPanel>
          </TabView>
        </div>
      </Dialog>
      <li
        className="top-icon"
        onClick={() => {
          setShow("Appointments")
        }}
      >
        <span className="material-icons top-icon-span">{icon}</span>
        <label className="top-menu-label">Appointments</label>
      </li>
      <Dialog
        header={() => (
          <div
            style={{
              paddingLeft: "10px",
            }}
          >
            <h4
              style={{
                margin: 0,
                marginBottom: "15px",
                color: "#757575",
                fontWeight: "300",
                fontSize: "20px",
              }}
            >
              {apptModalMode}
            </h4>
          </div>
        )}
        visible={modalIsOpen}
        onHide={() => {
          setIsOpen(false)
          setinitPayload({})
        }}
        style={{ width: "664px" }}
        headerStyle={{ paddingBottom: "0px" }}
        footer={() => {
          return (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                onClick={() => {
                  setIsOpen(false)
                  setinitPayload({})
                }}
                className="btn"
                style={{ marginRight: "5px" }}
              >
                Close
              </Button>
              {activeAppointment.values.type !== "NEW" ? (
                <Button
                  onClick={() => requestNewAppointment(true, false)}
                  className="btn btn-danger"
                  style={{
                    marginRight: "5px",
                    display:
                      apptModalMode === "Request Appointment" ? "none" : "",
                  }}
                >
                  Request Cancel
                </Button>
              ) : null}
              {activeAppointment.values.type === "NEW" ? (
                <Button
                  onClick={() => {
                    confirmDelete(activeAppointment.values.id)
                  }}
                  className="btn btn-danger"
                  style={{
                    marginRight: "5px",
                    display:
                      apptModalMode === "Request Appointment" ? "none" : "",
                  }}
                >
                  Delete Request
                </Button>
              ) : null}
              <Button
                label={
                  apptModalMode.includes("Change")
                    ? "Request Appointment Change"
                    : apptModalMode.includes("Request")
                    ? "Request Appointment"
                    : "Edit Request"
                }
                type="submit"
                className={styles.btn}
                disabled={
                  JSON.stringify(initPayload) ===
                    JSON.stringify(activeAppointment.values) &&
                  activeAppointment.values?.cron === activeCron?.cronValue
                }
                onClick={() => requestNewAppointment()}
              />
            </div>
          )
        }}
      >
        <form
          onSubmit={activeAppointment.handleSubmit}
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          <div style={{ ...customStyles.inputField, width: "95%" }}>
            <label htmlFor="startDate">Staff</label>
            <br />
            <Dropdown
              onChange={activeAppointment.handleChange}
              value={assignedStaff}
              style={customStyles.inputStyle}
              options={assignedStaffList}
              optionLabel="displayName"
              className={classNames({
                "p-invalid": isFormFieldValid(activeAppointment, "staff"),
              })}
              disabled
            />
          </div>
          <div style={customStyles.inputField}>
            <label htmlFor="startDate">Appointment Type</label>
            <br />
            <Dropdown
              id="serviceName"
              disabled={activeAppointment.values.isRequest}
              onChange={activeAppointment.handleChange}
              value={activeAppointment.values.serviceName}
              style={customStyles.inputStyle}
              options={services}
              className={classNames({
                "p-invalid": isFormFieldValid(activeAppointment, "serviceName"),
              })}
            />
          </div>
          <div style={customStyles.inputField}>
            <label htmlFor="startTime">Duration</label>
            <br />
            <Dropdown
              id="duration"
              onChange={onDurationSelect}
              value={activeAppointment.values.duration}
              style={customStyles.inputStyle}
              options={durations}
              optionLabel="name"
              className={classNames({
                "p-invalid": isFormFieldValid(activeAppointment, "duration"),
              })}
            />
          </div>
          {toggleRecurringAppt()}
          <div
            hidden={apptModalMode.includes("Edit")}
            style={{
              ...customStyles.inputField,
              width: "95%",
              marginBottom: "10px",
            }}
          >
            <Checkbox
              id="isRecurring"
              onChange={activeAppointment.handleChange}
              checked={activeAppointment.values.isRecurring}
              disabled={apptModalMode === "Request Appointment Change"}
            ></Checkbox>{" "}
            <label htmlFor="startDate">Recurring Appointment</label>
          </div>
          <div style={{ ...customStyles.inputField, width: "95%" }}>
            <label htmlFor="startDate">Engagement Channel</label>
            <br />
            <Dropdown
              id="engagementChannel"
              onChange={activeAppointment.handleChange}
              value={activeAppointment.values.engagementChannel}
              style={{
                ...customStyles.inputStyle,
                textTransform: "capitalize",
              }}
              panelStyle={{ textTransform: "capitalize" }}
              options={engagementChannels}
              className={classNames({
                "p-invalid": isFormFieldValid(
                  activeAppointment,
                  "engagementChannel"
                ),
              })}
            />
          </div>
        </form>
      </Dialog>
      <Dialog
        header={() => (
          <div style={{ paddingLeft: "10px" }}>
            <h4
              style={{
                margin: 0,
                marginBottom: "15px",
                color: "#757575",
                fontSize: "20px",
              }}
            >
              Confirm Action
            </h4>
          </div>
        )}
        visible={confirmModalIsOpen}
        onHide={() => setConfirmIsOpen(false)}
        style={{ width: "25%" }}
        headerStyle={{ paddingBottom: "0px" }}
        footer={() => (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              onClick={() => setConfirmIsOpen(false)}
              className="btn"
              style={{ marginRight: "5px" }}
            >
              Cancel
            </Button>
            <Button
              label="Confirm"
              type="submit"
              className={styles.btn}
              onClick={() => confirmAction()}
            />
          </div>
        )}
      >
        <h3>{confirming.message}</h3>
      </Dialog>
    </>
  )
}
