import { useCallback, useContext, useEffect, useState } from "react"
import { DateTime } from "luxon"

//PrimeReact
import { Dialog } from "primereact/dialog"

// Context
import { UserContext } from "../../../context/userContext"

//styling
import myStyles from "../../../styles/assessments/AssessmentForm.module.css"

//SOAR Helpers
import { LoaderMedium } from "../../../components/Loaders"
import { LogError } from "../../../helpers/logger"

//Assessment components
import {
  assessmentsApiQuestionsGet,
  assessmentsApiCompletePost,
  assessmentsApiCompletedGet,
  assessmentsApiCompleteWithSessionPost,
  assessmentsApiUpdateCompletedPost,
} from "../assessmentsApi"
import AssessmentFormOutcome from "./AssessmentFormOutcome"
import AssessmentFormQuestionContainer from "./AssessmentFormQuestionContainer"
import AssessmentFormInstruction from "./AssessmentFormInstruction"

//Custom Participant form, do not replace with soar.dev.client AssessmentForm
export default function AssessmentForm({
  assessmentId,
  completedAssessmentId,
  taskId,
  submitSoarSession, //If true, submit completed assessments with session creation to Assessment service
  sessionLocation,
  readOnly,
  showForm,
  onCancel,
  onSubmit,
}) {
  const userCtx = useContext(UserContext)
  submitSoarSession = false //Override to always be false. Keep parameter to align with staff.client

  const [disable, setDisable] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [page, setPage] = useState(0)
  const [assessment, setAssessment] = useState(undefined)
  const [answers, setAnswers] = useState(undefined)
  const [finalOutcomes, setFinalOutcomes] = useState([])
  const [finalRemarks, setFinalRemarks] = useState("")
  const [isUserAnswers, setIsUserAnswers] = useState(false)
  const [startDateTime] = useState(DateTime.utc())

  let displayCompletedOutcomes =
    completedAssessmentId &&
    (assessment?.displayDisposition ||
      assessment?.displayRemarks ||
      assessment?.displayScore)

  const processAnswerSource = (questions, answers) => {
    let newAnswers = []
    const addAnswer = (question) => {
      let value = null
      let optionValues = []

      if (answers) {
        const questionAnswer = answers.filter(
          (answer) => answer.questionId === question.id
        )

        if (question.type === "checkbox") {
          optionValues = questionAnswer
        } else if (questionAnswer.length > 0) {
          value = questionAnswer[0].answerValue
        }
      }

      newAnswers.push({
        questionId: question.id,
        type: question.type,
        required: question.required,
        conditionalSource: question.conditionalSource,
        conditionalType: question.conditionalType,
        conditionalValue: question.conditionalValue,
        conditionalDisplay: question.conditionalDisplay,
        conditionalRequired: question.conditionalRequired,
        value: value,
        scoreFromAnswer: question.scoreFromAnswer,
        multiValues: question.options?.map((option) => {
          const optionValue = optionValues.filter(
            (optionValue) => optionValue.questionOptionId === option.id
          )

          return {
            optionId: option.id,
            value:
              optionValue.length > 0
                ? optionValue[0].answerValue === "true"
                : null,
            text: option.text,
            scoreValue: option.scoreValue,
          }
        }),
      })
    }

    questions.forEach((question) => {
      if (question.matrix) {
        question.matrix.groups.forEach((group) => {
          group.questions.forEach((groupQuestion) => {
            addAnswer(groupQuestion)
          })
        })
      } else if (question.questionGroup) {
        question.questionGroup.questions.forEach((groupQuestion) => {
          addAnswer(groupQuestion)
        })
      } else if (question.question) {
        addAnswer(question.question)
      }
    })

    setAnswers({ answers: newAnswers })
  }

  const loadCompletedAssessment = useCallback(async () => {
    if (completedAssessmentId) {
      setDisable(true)
      assessmentsApiCompletedGet(
        userCtx.tokenAssessments,
        completedAssessmentId
      )
        .then((result) => {
          setAssessment({
            assessmentName: result.assessment.name,
            completedAssessmentId: result.completedAssessment.id,
            score: result.completedAssessment.scoreValue,
            displayAnswers: result.assessment.displayAnswers,
            displayAnswerValues: result.assessment.displayAnswerValues,
            displayDisposition: result.assessment.displayDisposition,
            displayQuestions: result.assessment.displayQuestions,
            displayRemarks: result.assessment.displayRemarks,
            displayScore: result.assessment.displayScore,
            questions: result.questions,
            suggestedOutcomes: result.suggestedOutcomes,
          })
          setFinalOutcomes(
            result.finalOutcomes?.map((outcome) => {
              return { ...outcome, selected: true }
            })
          )
          setFinalRemarks(result.completedAssessment.remarks)
          processAnswerSource(result.questions, result.answers)
          setDisable(false)
        })
        .catch((error) => {
          LogError(
            `Unable to load completed assessment profile for ID ${completedAssessmentId}`,
            error
          )
          userCtx.setNotificationError(
            "Unable to load completed assessment profile"
          )
          onCancel()
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedAssessmentId])

  useEffect(() => {
    loadCompletedAssessment()
  }, [loadCompletedAssessment])

  const loadAssessmentProfile = useCallback(async () => {
    if (assessmentId && !completedAssessmentId) {
      setDisable(true)
      assessmentsApiQuestionsGet(userCtx.tokenAssessments, assessmentId)
        .then((result) => {
          setAssessment(result)
          processAnswerSource(result.questions)
          setDisable(false)
        })
        .catch((error) => {
          LogError(
            `Unable to load assessment profile for ID ${assessmentId}`,
            error
          )
          userCtx.setNotificationError("Unable to load assessment profile")
          onCancel()
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentId])

  useEffect(() => {
    loadAssessmentProfile()
  }, [loadAssessmentProfile])

  useEffect(() => {}, [assessment])

  const handleAssessmentSubmit = async (
    finalAnswers,
    finalOutcomes,
    remarks
  ) => {
    try {
      setDisable(true)
      setSubmitting(true)
      const singleValueAnswers = finalAnswers.answers
        .filter((answer) => answer.value)
        .map((answer) => {
          return {
            questionId: answer.questionId,
            answerValue: answer.value.toString(),
          }
        })

      let multiValueAnswers = []
      finalAnswers.answers
        .filter(
          (answer) =>
            !answer.value &&
            answer.multiValues?.some((multiValue) => multiValue.value)
        )
        .forEach((answer) => {
          multiValueAnswers.push(
            ...answer.multiValues
              .filter((multiValue) => multiValue.value)
              .map((multiValue) => {
                return {
                  questionId: answer.questionId,
                  optionId: multiValue.optionId,
                  answerValue: multiValue.value.toString(),
                }
              })
          )
        })

      let useFinalOutcomes = finalOutcomes.filter((outcome) => outcome.selected)

      let payload = {
        assessmentId: assessmentId,
        assignmentTaskId: taskId,
        remarks: remarks,
        staffId: undefined,
        staffAlias: undefined,
        participantId: userCtx.participant_id,
        participantAlias: userCtx.participant_alias,
        enterpriseId: userCtx.enterprise_id,
        enterpriseAlias: userCtx.enterprise_alias,
        organizationId: userCtx.organization_id,
        organizationAlias: userCtx.organization_alias,
        completedDate: DateTime.utc(),
        creatorId: userCtx.participant_id, //Different from soar.dev.client due to the participant being the creator
        answers: [...singleValueAnswers, ...multiValueAnswers],
        finalOutcomes: useFinalOutcomes,
      }

      let result = undefined

      if (completedAssessmentId) {
        result = await assessmentsApiUpdateCompletedPost(
          userCtx.tokenAssessments,
          completedAssessmentId,
          payload
        )
      } else {
        if (submitSoarSession) {
          const sessionPayload = {
            completedAssessment: payload,
            startDateTime: startDateTime.toISO(),
            endDateTime: DateTime.utc().toISO(),
            locationId: sessionLocation?.location_id,
            locationName: sessionLocation?.location_name,
          }

          result = await assessmentsApiCompleteWithSessionPost(
            userCtx.tokenAssessments,
            sessionPayload
          )
        } else {
          result = await assessmentsApiCompletePost(
            userCtx.tokenAssessments,
            payload
          )
        }
      }

      if (result) {
        setDisable(false)
        setSubmitting(false)
        await onSubmit(result.completedAssessment.id)
      } else {
        LogError("Unable to save assessment profile, result is: ", result)
        userCtx.setNotificationError("Unable to save assessment profile")
        setDisable(false)
        setSubmitting(false)
        return false
      }
    } catch (error) {
      LogError(`Unable to save assessment`, error)
      userCtx.setNotificationError("Unable to save assessment profile")
      setDisable(false)
      setSubmitting(false)
      return false
    }
  }

  return (
    <Dialog
      header={`Assessment${assessment ? `: ${assessment.assessmentName}` : ""}`}
      visible={showForm}
      onHide={onCancel}
      className={myStyles.dialog}
    >
      {!answers ? <LoaderMedium /> : null}
      {submitting ? (
        <div className={myStyles.submitLayout}>
          <div className={myStyles.submitLayoutDescription}>
            Submitting assessment, please wait...
          </div>
          <div className={myStyles.submitLayoutLoader}>
            <LoaderMedium />
          </div>
        </div>
      ) : null}
      {answers && !submitting ? (
        <>
          <AssessmentFormInstruction instruction={assessment.instruction} />
          <div className={page === 1 ? "" : myStyles.mainLayout}>
            {assessment.completedAssessmentId ? (
              <>
                <div className={myStyles.mainLayoutOutcomesHeader}>Overall</div>
                <div className={myStyles.mainLayoutFormHeader}>Assessment</div>
              </>
            ) : null}

            {page === 1 || displayCompletedOutcomes ? (
              <div className={page === 1 ? "" : myStyles.mainLayoutOutcomes}>
                <AssessmentFormOutcome
                  answers={answers}
                  outcomes={assessment?.suggestedOutcomes}
                  outcomesSelected={finalOutcomes}
                  remarksValue={finalRemarks}
                  onCancel={(finalOutcomes, remarks) => {
                    setPage(0)
                    setFinalOutcomes(finalOutcomes || [])
                    setFinalRemarks(remarks || "")
                  }}
                  onSubmit={async (finalOutcomes, remarks) => {
                    await handleAssessmentSubmit(
                      answers,
                      finalOutcomes,
                      remarks
                    )
                  }}
                  disable={disable}
                  completedAssessment={
                    assessment.completedAssessmentId ? assessment : undefined
                  }
                  validateScore={assessment.validateScore}
                  minScore={assessment.minScore}
                  maxScore={assessment.maxScore}
                />
              </div>
            ) : null}

            {page === 0 || assessment.completedAssessmentId ? (
              <div className={myStyles.mainLayoutForm}>
                <AssessmentFormQuestionContainer
                  questions={assessment?.questions}
                  answers={answers}
                  alwaysAllowSubmit={isUserAnswers}
                  onSubmitValues={(answers) => {
                    setAnswers(answers)
                    setIsUserAnswers(true)

                    //Note: Participant assessment forms always submit assessments, no outcomes captured.
                    handleAssessmentSubmit(answers, finalOutcomes, finalRemarks)
                  }}
                  onCancel={onCancel}
                  readOnly={readOnly}
                  disable={disable}
                  completedAssessment={completedAssessmentId}
                  displayQuestions={assessment?.displayQuestions}
                  displayAnswerValues={assessment?.displayAnswerValues}
                  displayAnswers={assessment?.displayAnswers}
                  displayScore={
                    assessment?.completedAssessmentId
                      ? assessment?.displayScore
                      : false
                  }
                />
              </div>
            ) : null}
          </div>
        </>
      ) : null}
    </Dialog>
  )
}
