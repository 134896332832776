import { useContext } from "react"
import { Field, useFormikContext } from "formik"
import { DateTime } from "luxon"
import _ from "lodash"

import { FormContext } from "../../../context/formContext"
import { Calendar } from "primereact/calendar"

import styles from "../../../styles/Form.module.css"

import fieldVisibility from "../../../helpers/fieldVisibility"

export default function TypesSessionStartTime({ field, block, index }) {
  const form = useContext(FormContext)
  const formik = useFormikContext()

  const isDisabled = form ? form.blocksSaved[block] : false
  const checkIfRequired = () => {
    try {
      return JSON.parse(field.field_validation.required[0])
    } catch (error) {
      return false
    }
  }

  const handleChange= ({ e, form }) => {
    const fieldSessionDate = formik?.getFieldProps("session_date")
    const sessionDate = DateTime.fromJSDate(fieldSessionDate.value).toObject()
    const startTime = DateTime.fromJSDate(e.value).toObject()

    const x = DateTime.fromFormat(`${sessionDate.year}-${sessionDate.month}-${sessionDate.day} ${startTime?.hour || '00'}:${startTime?.minute || '00' }`, 'y-M-d h:m').toJSDate()
    form.setFieldValue("session_start_time", x)
    form.setFieldValue("session_date", x)
  }
  
  const isRequired = checkIfRequired()
  return (
    <div
      className={styles.field}
      style={{
        order: index,
        display: fieldVisibility(field.field_visibility),
      }}
    >
      <label className={styles.label}>
        {field.field_label}
        {isRequired && "*"}
      </label>
      {field.field_description && (
        <div className={styles.description}>{field.field_description}</div>
      )}
      <Field name={field.field_name}>
        {({ field: formikField, meta, form }) => (
          <>
            <Calendar
              id={field.field_name}
              name={formikField.name}
              value={formikField.value}
              onChange={(e) => handleChange({ e, form })}
              disabled={isDisabled}
              onBlur={formikField.onBlur}
              timeOnly
              hourFormat="12"
            />
            {meta.touched && meta.error && (
              <div className={styles.errFieldMsg}>{meta.error}</div>
            )}
          </>
        )}
      </Field>
    </div>
  )
}
